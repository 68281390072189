import { useEffect } from "react";
import clsx from "clsx";
import useRouter from "found/useRouter";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import MultiAssestAccess from "../MultiAssestAccess";

import NavItem from "./NavItem";

import items from "../nav-items";
import dropdownsItems from "../nav-dropdown";
import tabBarItems from "../tab-bar-items";
import { isAdmin } from "common/RedirectNonAdmin";
import useWIAssetService from "common/hooks/useWIAssetService";
import { ReactComponent as ArrowLeftIcon } from "common/images/nav-arrow-left.svg";
import Body4 from "common/Typography/Body4";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {},
    mobileDrawer: {
      width: drawerWidth,
      backgroundColor: "#0C0B0D",
    },
    desktopDrawer: {
      width: drawerWidth,
      backgroundColor: "#F2F2FC",
    },
    boxContent: {
      marginTop: "92px",

      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    hide: {
      display: "none",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    assestsAccessContainer: {
      display: "flex",
      alignItems: "center",
      height: "68px",
    },

    boxItemNav: {
      display: "flex",

      width: "100%",
      padding: "12px 24px 0 10px;",
      letterSpacing: "0",
      textTransform: "none",
      justifyContent: "flex-start",
      gap: "8px",
      alignItems: "center",
      color: theme.palette.primary.main,
      "& $activeFirstNavItem > path": {
        fill: "#6C2BFF",
      },
    },
    activeFirstNavItem: {
      color: theme.palette.primary.main,
    },
  })
);

interface IProps {
  onMobileClose: () => void;
  onMobileOpen: () => void;
  onMobilePageClose: () => void;
  onMobilePageOpen: () => void;
  openPageMobile: boolean;
  openMobile: boolean;
  isOpen: boolean;
  setOpen: any;
}

const NavBar = ({
  onMobileOpen,
  onMobileClose,
  onMobilePageClose,
  onMobilePageOpen,
  openMobile,
  openPageMobile,
  isOpen,
  setOpen,
}: IProps) => {
  const classes = useStyles();
  const { match } = useRouter();
  const { assets } = useWIAssetService();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match!.location]);

  useEffect(() => {
    if (openPageMobile && onMobilePageClose) {
      onMobilePageClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match!.location]);

  const contentMobile = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxContent}
    >
      <Box py={0}>
        <div className={classes.assestsAccessContainer}>
          <MultiAssestAccess assestsAccess={assets} />
        </div>
        <List style={{ padding: 0 }}>
          {items.map((item, index) => (
            <NavItem
              index={index}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              target={item.target}
              defaultActive={item.defaultActive}
              onClickNavItem={onMobilePageOpen}
              variant="navdropdown"
            />
          ))}

          {isAdmin() && (
            <NavItem
              index={items.length}
              href="/admin/dashboard"
              title="Admin"
              icon={null}
              target="_self"
            />
          )}
        </List>
      </Box>
    </Box>
  );

  const contentMobilePage = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxContent}
    >
      <Box py={0}>
        <div className={classes.assestsAccessContainer}>
          <MultiAssestAccess assestsAccess={assets} />
        </div>
        <Box onClick={onMobileOpen} className={classes.boxItemNav}>
          <ArrowLeftIcon className={classes.activeFirstNavItem} />
          <Body4 variant="bold" color="inherit">
            {items[0].title}
          </Body4>
        </Box>
        <List>
          {dropdownsItems.map((item, index) => (
            <NavItem
              index={index}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              target={item.target}
              defaultActive={item.defaultActive}
              mainmenu={false}
              variant="navdropdown"
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxContent}
    >
      <Box py={0}>
        <List>
          {tabBarItems.map((item, index) => (
            <NavItem
              index={index}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              target={item.target}
            />
          ))}

          {isAdmin() && (
            <NavItem
              index={items.length}
              href="/admin/dashboard"
              title="Admin"
              icon={null}
              target="_self"
            />
          )}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {contentMobile}
        </Drawer>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => {
            onMobileClose();
            onMobilePageClose();
          }}
          open={openPageMobile}
          variant="temporary"
        >
          {contentMobilePage}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          open
          variant="permanent"
        >
          <Toolbar />

          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
