import {
  takeEvery,
  takeLatest,
  delay,
  all,
  call,
  put,
} from "redux-saga/effects";
import { cloneDeep, keyBy, difference, toString } from "lodash";
import format from "date-fns/format";
import moment from "moment";

import {
  backEndRequest,
  articleRequest as articleRequestConfig,
} from "common/request";
import { actions } from "common/stores/authenticated/publication";
import { FETCH_STATUS } from "common/stores/constants";

import {
  NEWSLETTER_TYPE_VALUE,
  RANKING_TYPE_VALUE,
  MARKET_TYPE_VALUE,
  SPECIAL_TYPE_VALUE,
  EVENT_TYPE_VALUE,
} from "util/PublicationTypes";

const request = backEndRequest();
const articleRequest = articleRequestConfig();

function* activeFirstYearAndFirstPublication(yearsAgr) {
  const years = cloneDeep(yearsAgr);

  const firstYear = years[0];
  const firstYearValue = firstYear.value;
  const publicationParams = {
    year: firstYearValue,
    type: NEWSLETTER_TYPE_VALUE,
  };

  const { data } = yield call(request.post, "/publications", publicationParams);

  const publications = data.data;

  years[0].publications.data = publications;
  years[0].publications.status = FETCH_STATUS.succeeded;
  years[0].isActive = true;

  if (publications.length) {
    yield put(actions.setPublicationActive(publications[0]));
  }

  return yield years;
}

function* activeYearAndPublicationByPublicationId(yearsAgr, publication) {
  const years = cloneDeep(yearsAgr);

  const { publishedAt } = publication;
  const yearPublishedAt = Number(format(new Date(publishedAt), "yyyy"));

  const yearIndexActive = years.findIndex(
    (year) => year.value === yearPublishedAt
  );

  const activeYear = years[yearIndexActive];
  const activeYearValue = activeYear.value;
  const publicationParams = {
    year: activeYearValue,
    type: NEWSLETTER_TYPE_VALUE,
  };

  const { data } = yield call(request.post, "/publications", publicationParams);

  const publications = data.data;

  years[yearIndexActive].publications.data = publications;
  years[yearIndexActive].publications.status = FETCH_STATUS.succeeded;
  years[yearIndexActive].isActive = true;

  if (publications.length) {
    yield put(actions.setPublicationActive(publication));
  }

  return yield years;
}

function* watchFetchYear({ payload }) {
  yield put(actions.setYearStatus(FETCH_STATUS.loading));

  const publicationId = payload || "";
  let publication = null;

  try {
    const yearParams = {
      type: NEWSLETTER_TYPE_VALUE,
    };

    const { data } = yield call(
      request.post,
      "/publications/group/year",
      yearParams
    );

    let years = data?.years || [];

    years = years.map((year) => ({
      isActive: false,
      value: year,
      publications: {
        status: FETCH_STATUS.idle,
        error: null,
        data: [],
      },
    }));

    if (Boolean(publicationId)) {
      const { data: publicationRes } = yield call(
        request.get,
        `/publications/${publicationId}`
      );

      if (publicationRes.type === NEWSLETTER_TYPE_VALUE) {
        publication = publicationRes;
      }
    }

    // get first year and first publication and active first publication
    if (years.length) {
      if (Boolean(publication) === false) {
        years = yield activeFirstYearAndFirstPublication(years);
      } else {
        years = yield activeYearAndPublicationByPublicationId(
          years,
          publication
        );
      }
    }

    yield put(actions.setYear(years));
    yield put(actions.setYearStatus(FETCH_STATUS.succeeded));
  } catch (error) {
    console.log(error);
    yield put(actions.setYearStatus(FETCH_STATUS.failed));
  }
}

function* watchToggleActiveYear({ payload }) {
  const { year, isActive, publicationStatus } = payload;

  try {
    yield put(
      actions.setActiveYear({
        year,
        isActive,
        publications: null,
      })
    );

    if (publicationStatus !== FETCH_STATUS.succeeded) {
      yield put(
        actions.setsetPublicationYearStatus({
          year,
          status: FETCH_STATUS.loading,
        })
      );

      const publicationParams = {
        year,
      };

      const { data } = yield call(
        request.post,
        "/publications",
        publicationParams
      );

      const publications = data.data;

      yield put(
        actions.setActiveYear({
          year,
          isActive,
          publications,
        })
      );

      yield put(
        actions.setsetPublicationYearStatus({
          year,
          status: FETCH_STATUS.succeeded,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.setsetPublicationYearStatus({
        year,
        status: FETCH_STATUS.failed,
      })
    );
  }
}

function* watchSetPublicationActive({ payload }) {
  yield delay(300);

  try {
    const publication = payload;

    yield put(actions.setArticleStatus(FETCH_STATUS.loading));

    const fetchArticlesGeneral = call(articleRequest.get, "/articles", {
      params: {
        type: "general",
        publicationId: publication.id,
        sorts: ["createdAt.ASC"],
      },
    });

    const fetchArticlesGrapevine = call(articleRequest.get, "/articles", {
      params: {
        type: "grapevine",
        publicationId: publication.id,
        sorts: ["createdAt.DESC"],
      },
    });

    const [{ data: articlesGeneral }, { data: articlesGrapevine }] = yield all([
      fetchArticlesGeneral,
      fetchArticlesGrapevine,
    ]);

    const { data: generalOrderArticleData } = yield call(
      articleRequest.get,
      `/articles/order/publication/${publication.id}`,
      {
        params: {
          type: "general",
        },
      }
    );

    let articlesGeneralData = articlesGeneral?.data || [];

    if (Boolean(generalOrderArticleData?.data)) {
      const articlesGeneralDataKeyById = keyBy(articlesGeneralData, "id");
      const articlesGeneralDataIds = articlesGeneralData.map(
        (article) => article.id
      );
      let generalOrderArticleDataIds = toString(
        generalOrderArticleData?.data?.orderIds
      );
      generalOrderArticleDataIds = Boolean(generalOrderArticleDataIds)
        ? generalOrderArticleDataIds.split(",")
        : [];

      const differenceIds = difference(
        articlesGeneralDataIds,
        generalOrderArticleDataIds
      );
      generalOrderArticleDataIds =
        generalOrderArticleDataIds.concat(differenceIds);

      articlesGeneralData = [];

      generalOrderArticleDataIds.forEach((id) => {
        const articleMapping = articlesGeneralDataKeyById[id];

        if (Boolean(articleMapping)) {
          articlesGeneralData.push({
            ...articleMapping,
          });
        }
      });
    }

    const articles = {
      general: [...articlesGeneralData],
      grapevine: [...(articlesGrapevine?.data || [])],
    };

    yield put(actions.setArticles(articles));
    yield put(actions.setArticleStatus(FETCH_STATUS.succeeded));
  } catch (error) {
    console.log(error);
    yield put(actions.setArticleStatus(FETCH_STATUS.failed));
  }
}

function* fetchPublicationByTopic({ payload }) {
  yield put(actions.setRankingStatus(FETCH_STATUS.loading));

  const { topicId } = payload;
  yield put(actions.setRankingCurrentTopic(topicId));

  try {
    const publicationParams = {
      topic: topicId,
      type: RANKING_TYPE_VALUE,
    };

    const { data } = yield call(
      request.post,
      "/publications",
      publicationParams
    );

    const publications = data.data;

    const groupByYear = {};

    const result = publications.map((item) => {
      const currentItemYear = moment(item.publishedAt).year();
      if (groupByYear && !groupByYear[currentItemYear]) {
        groupByYear[currentItemYear] = [];
      }
      groupByYear[currentItemYear].push(item);
      return {
        yearLabel: currentItemYear,
        ...item,
      };
    });

    yield put(actions.setRanking(result));
    yield put(actions.setRankingStatus(FETCH_STATUS.succeeded));
  } catch (error) {
    console.log(error);
    yield put(actions.setRankingStatus(FETCH_STATUS.error));
  }
}

function* watchFetchMarketParticipants({ payload }) {
  yield put(actions.setMarketParticipantStatus(FETCH_STATUS.loading));

  const publicationId = payload || "";
  let publication = null;

  try {
    const publicationParams = {
      type: MARKET_TYPE_VALUE,
    };

    const { data } = yield call(
      request.post,
      "/publications",
      publicationParams
    );
    yield put(actions.setMarketParticipants(data));

    yield put(actions.setMarketParticipantStatus(FETCH_STATUS.succeeded));
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketParticipantStatus(FETCH_STATUS.failed));
  }
}

function* watchFetchSpecialFeatures({ payload }) {
  yield put(actions.setSpecialFeaturesStatus(FETCH_STATUS.loading));

  const publicationId = payload || "";
  let publication = null;

  try {
    const publicationParams = {
      type: SPECIAL_TYPE_VALUE,
    };

    const { data } = yield call(
      request.post,
      "/publications",
      publicationParams
    );
    yield put(actions.setSpecialFeatures(data));

    yield put(actions.setSpecialFeaturesStatus(FETCH_STATUS.succeeded));
  } catch (error) {
    console.log(error);
    yield put(actions.setSpecialFeaturesStatus(FETCH_STATUS.failed));
  }
}

function* watchFetchEvents({ payload }) {
  yield put(actions.setEventsStatus(FETCH_STATUS.loading));

  const publicationId = payload || "";
  let publication = null;

  try {
    const publicationParams = {
      type: EVENT_TYPE_VALUE,
    };

    const { data } = yield call(
      request.post,
      "/publications",
      publicationParams
    );
    yield put(actions.setEvents(data));

    yield put(actions.setEventsStatus(FETCH_STATUS.succeeded));
  } catch (error) {
    console.log(error);
    yield put(actions.setEventsStatus(FETCH_STATUS.failed));
  }
}

export default function* publicationMiddleware() {
  yield takeEvery(actions.fetchYear.toString(), watchFetchYear);
  yield takeEvery(actions.toggleActiveYear.toString(), watchToggleActiveYear);
  yield takeEvery(
    actions.fetchPublicationByTopic.toString(),
    fetchPublicationByTopic
  );

  yield takeLatest(
    actions.setPublicationActive.toString(),
    watchSetPublicationActive
  );

  yield takeLatest(
    actions.fetchMarketParticipants.toString(),
    watchFetchMarketParticipants
  );

  yield takeLatest(
    actions.fetchSpecialFeatures.toString(),
    watchFetchSpecialFeatures
  );

  yield takeLatest(actions.fetchEvents.toString(), watchFetchEvents);
}
