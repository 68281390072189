import { useState } from "react";
import RouterLink from "found/Link";
import useRouter from "found/useRouter";
import clsx from "clsx";

import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Tooltip from "@material-ui/core/Tooltip";

import useAuth from "common/hooks/useAuth";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      width: 60,
      height: 60,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      fontWeight: 600,
      color: "#fff",
      // fontSize: '2.2243rem',
      // marginRight: theme.spacing(1),
      // marginLeft: theme.spacing(1),
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

const TopBar = ({
  className,
  onMobileNavOpen,
  isOpen,
  setOpen,
  ...rest
}: IProps) => {
  const classes = useStyles();
  const { router } = useRouter();
  const { isLoggedin, logout } = useAuth();
  const [notifications] = useState([]);

  console.log(isLoggedin);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onLogIn = (event: any) => {
    if (isLoggedin) {
      event.preventDefault();

      logout();
      // window.location.reload();
    }

    router.replace("/user/login");
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Hidden mdDown>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <RouterLink to="/admin/dashboard" style={{ textDecoration: "none" }}>
          <Typography
            component="h1"
            variant="h6"
            display="inline"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
        </RouterLink>

        <Tooltip arrow title="Switch to Customer Portal">
          <IconButton color="inherit" to="/publications" component={RouterLink}>
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>

        <Box flexGrow={1} />

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <IconButton color="inherit" onClick={onLogIn}>
          {isLoggedin ? <InputIcon /> : <LockOpenIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
