import { lazy } from "react";
import graphql from "babel-plugin-relay/macro";
// import RedirectException from "found/RedirectException";
//import HttpError from "found/HttpError";

import Layout from "common/Layouts/Admin";
import { isLoggedin } from "common/hooks/useAuth";
import RedirectNonAdmin, { redirectUrl } from "common/RedirectNonAdmin";

const routes = [
  {
    path: "/admin/publications",
    query: graphql`
      query PublicationAdminQuery {
        ...ListAdmin_publications
      }
    `,
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./List")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/publications/add",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./Add")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/publications/edit/:id",
    query: graphql`
      query PublicationAdminEditQuery($id: ID!) {
        publication(id: $id) {
          ...EditAdminPublication_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./Edit")),
    prepareVariables: (params) => ({ id: params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      //   if (error) {
      //     throw new HttpError(404, error);
      //   }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.publication : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/admin/publication/:id",
    query: graphql`
      query PublicationAdminViewQuery($id: ID!) {
        publication(id: $id) {
          ...ViewAdminPublication_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./View")),
    prepareVariables: (params) => ({ id: params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.publication : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/admin/emails",
    query: graphql`
      query PublicationAdminEmailqueueQuery {
        ...QlistAdmin_emailqueues
      }
    `,
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./Qlist")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/email/add",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./Queue")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/email/edit/:id",
    query: graphql`
      query PublicationAdminEditQueueQuery($id: ID!) {
        emailqueue(id: $id) {
          ...EditQueueAdminPublication_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./EditQueue")),
    prepareVariables: (params) => ({ id: params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      //   if (error) {
      //     throw new HttpError(404, error);
      //   }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.emailqueue : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/admin/articles",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./ArticleList")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/articles/add",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./AddArticle")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/articles/edit/:id",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./EditArticle")),
    prepareVariables: (params) => ({ id: params.id || "" }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
];

export default routes;
