import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

import Body4 from "./Typography/Body4";
import Button from "./Button";

let openAuthPopupFn: any;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      minHeight: "calc(100% - 64px)",
      minWidth: "100%",
      zIndex: theme.zIndex.drawer,
      backgroundColor: "rgba(0, 0, 0, 0.9)",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      [theme.breakpoints.down("xs")]: {
        minHeight: "calc(100% - 56px)",
        padding: "0 16px",
      },
    },

    popupContainer: {
      minWidth: "100%",
      minHeight: "396px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.08)",
      borderRadius: "8px",
      paddingTop: "80px",
      paddingBottom: "32px",
      borderBottom: `6px solid ${theme.palette.primary.main}`,

      [theme.breakpoints.up("sm")]: {
        minWidth: "600px",
      },
    },

    groupIconContainer: {
      width: "196px",
      height: "118px",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.08)",
      borderRadius: "8px",
      overflow: "hidden",
      position: "relative",
      backgroundColor: "#FFFFFF",
      marginBottom: "24px",
    },

    groupIconContainerLeft: {
      width: "50%",
      height: "100%",
      backgroundColor: "#FFFFFF",
      display: "inline-block",
    },

    groupIconContainerRight: {
      width: "50%",
      height: "100%",
      backgroundColor: "#F4F4F4",
      display: "inline-block",
    },

    heading: {
      color: "#26262C",
      marginBottom: "16px",
    },

    subtitle: {
      color: "#26262C",
      textAlign: "center",
      marginBottom: "16px",
      maxWidth: "400px",
    },

    buttonGroupContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& > :first-child": {
        marginRight: "16px",
      },
    },
  })
);

const useStylesThreeDotIcon = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      zIndex: 1,
      left: 8,
      top: 6,
    },
  })
);

const ThreeDotIcon = (): JSX.Element => {
  const classes = useStylesThreeDotIcon();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="5"
      fill="none"
      viewBox="0 0 19 5"
      className={classes.root}
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill="#EA1313"></circle>
      <circle cx="9.5" cy="2.5" r="2.5" fill="#E0AA1E"></circle>
      <circle cx="16.5" cy="2.5" r="2.5" fill="#008937"></circle>
    </svg>
  );
};

const useStylesLockIcon = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      zIndex: 1,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  })
);

const LockIcon = (): JSX.Element => {
  const classes = useStylesLockIcon();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="32"
      fill="none"
      viewBox="0 0 24 32"
      className={classes.root}
    >
      <path
        fill="#63626A"
        fillRule="evenodd"
        d="M19.917 13.36V8.084a7.918 7.918 0 00-15.834 0v5.278H.125v18.472h23.75V13.361h-3.958zm-6.598 10.191v3.004h-2.638v-3.004a2.63 2.63 0 01-1.32-2.273 2.64 2.64 0 015.278 0c0 .973-.533 1.815-1.32 2.273zM6.722 8.083v5.278h10.556V8.083A5.284 5.284 0 0012 2.805a5.283 5.283 0 00-5.278 5.278z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const AuthPopup = (): JSX.Element | null => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleContactUs = () => {
    window.open("/subscribe-us", "_blank")?.focus();
  };

  React.useEffect(() => {
    openAuthPopupFn = handleOpen;
  }, []);

  return open ? (
    <div data-component="auth-popup" className={clsx(classes.root)}>
      <div className={clsx(classes.popupContainer)}>
        <div className={clsx(classes.groupIconContainer)}>
          <ThreeDotIcon />
          <LockIcon />

          <div className={clsx(classes.groupIconContainerLeft)} />
          <div className={clsx(classes.groupIconContainerRight)} />
        </div>

        <Typography
          variant="h6"
          component="h6"
          className={clsx(classes.heading)}
        >
          Upgrade to view this section
        </Typography>

        <Body4 fullWidth className={clsx(classes.subtitle)}>
          You don't have permission to access this page. Upgrade your account to
          view
        </Body4>

        <div className={clsx(classes.buttonGroupContainer)}>
          <Button variant="outlined" color="primary" onClick={handleGoBack}>
            Go back
          </Button>

          <Button color="primary" variant="contained" onClick={handleContactUs}>
            Contact us
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export const openAuthPopup = () => {
  console.log("Open Auth Popup");
  openAuthPopupFn();
};

export default AuthPopup;
