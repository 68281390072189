import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Inspired by the Facebook spinners.
const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  top: {
    color: "#eef3fd",
  },
  bottom: {
    color: "#6798e5",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
});

const FacebookProgress = (props) => {
  const classes = useStyles();
  const { className, ...other } = props;
  const wrapper = clsx(classes.root, className);

  return (
    <div className={wrapper}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={24}
        thickness={4}
        {...other}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={24}
        thickness={4}
        {...other}
      />
    </div>
  );
};

export default FacebookProgress;
