import React from "react";
import clsx from "clsx";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import MultiAssestAccess from "./MultiAssestAccess";
import Logo from "common/Logo";
import navItems from "./nav-items";
import dropdownItems from "./nav-dropdown";
import Body4 from "common/Typography/Body4";
import AccountDropdown from "./AccountDropdown";
import useWIAssetService from "common/hooks/useWIAssetService";
import { Link, Typography } from "@material-ui/core";

import { styled } from "@material-ui/core/styles";

const StyledUl = styled("ul")({
  gap: "16px 24px",
  top: "38px",
  width: "872px",
  height: "418px",
  margin: "0",
  flexWrap: "wrap",
  background: "#0C0B0D",
  boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.25)",
  listStyle: "none",
  alignContent: "flex-start",
  flexDirection: "column",
  justifyContent: "flex-start",
  position: "absolute",
  left: "-36px",
  zIndex: 64,
  borderRadius: "0.75rem",
  border: "1px solid rgb(67, 66, 72)",
  backgroundColor: "rgb(12, 11, 13)",
  padding: "2rem 1.5rem",
  "&:hover": {
    display: "flex",
    opacity: 1,
  },
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      minHeight: "68px",
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "#0C0B0D",
      borderBottom: "1px solid #63626A",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        left: 0,
        width: "100vw",
      },
    },
    appBarShift: {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      margin: "0px 2rem 0px 1rem",
      padding: "0",
      minHeight: "68px",
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",

      "& > :first-child": {
        marginRight: "20px",

        [theme.breakpoints.up("md")]: {
          marginRight: 0,
        },
      },
    },
    navContainer: {
      marginLeft: "3rem",
      display: "flex",
      alignItems: "center",
      position: "relative",

      "& > span": {
        padding: "0px 1rem",
        cursor: "pointer",
      },
    },
    navItem: {
      cursor: "pointer",
      // padding: "8px",
      // borderRadius: "4px",
      // "&:hover": {
      //   background: "#26262C",
      // },
    },
    navItemActive: {
      color: "#FFF",
      "&:hover": {
        color: "#AD86FB",
        "& $navDropdown": {
          display: "flex",
          opacity: 1,
        },
      },
      "&:hover + $navDropdown": {
        display: "flex",
        opacity: 1,
      },
    },
    navDropdown: {
      display: "none",
      opacity: "0",
      "&:hover": {
        display: "flex",
        opacity: 1,
      },
    },
    navDropdownItem: {
      borderRadius: "8px",
      "&:hover": {
        background: "#26262C",
      },
    },
    DropdownItemLink: {
      textDecoration: "none",
      color: "#fff",
      gap: "0px 0.75rem",
      width: "400px",
      height: "auto",
      display: "inline-flex",
      padding: "10px 12px",

      margin: "0",
      flexBasis: "auto",
      borderRadius: "8px",
      cursor: "pointer",

      "&:hover": {
        textDecoration: "none",
        // background: "#0C0B0D",
      },
    },
    DropdownIcon: {},
    DropdownTitle: {
      fontSize: "1rem",
      fontWeight: "initial",
    },
    navDropdownItemActive0: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#AD86FB",
        },
        "& $DropdownIcon > path": {
          fill: "#AD86FB",
        },
      },
    },
    navDropdownItemActive1: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#56B28B",
        },
        "& $DropdownIcon > path": {
          fill: "#56B28B",
        },
      },
    },
    navDropdownItemActive2: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#2BA7C2",
        },
        "& $DropdownIcon > path": {
          fill: "#2BA7C2",
        },
      },
    },
    navDropdownItemActive3: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#797BE1",
        },
        "& $DropdownIcon > path": {
          fill: "#797BE1",
        },
      },
    },
    navDropdownItemActive4: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#CFB67F",
        },
        "& $DropdownIcon > path": {
          fill: "#CFB67F",
        },
      },
    },
    navDropdownItemActive5: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#00D355",
        },
        "& $DropdownIcon > path": {
          fill: "#00D355",
        },
      },
    },
    navDropdownItemActive6: {
      "&:hover": {
        "& $DropdownTitle": {
          color: "#F27171",
        },
        "& $DropdownIcon > path": {
          fill: "#F27171",
        },
      },
    },
    navDropdownItemImage: {
      marginTop: "0.25rem",
      "& > svg": {
        width: "1.0625rem",
        height: "1.0625rem",
      },
    },
    navDropdownItemText: {
      display: "flex",
      flexDirection: "column",
      gap: "1.5px",
      marginTop: "1px",
    },
    topIconUp: {
      display: "block",
      position: "absolute",
      top: "-17px",
      left: "55px",
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  match?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

interface IMenuButtonProps {
  onClick: () => void;
}

const MenuButton = ({ onClick }: IMenuButtonProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <path
        fill="#fff"
        d="M15.148 6H4.852C4.382 6 4 5.553 4 5c0-.553.381-1 .852-1h10.296c.47 0 .852.447.852 1 0 .553-.381 1-.852 1zM19.17 13H4.83C4.37 13 4 12.553 4 12c0-.553.371-1 .83-1h14.34c.459 0 .83.447.83 1 0 .553-.371 1-.83 1zM15.148 20H4.852C4.382 20 4 19.553 4 19c0-.553.381-1 .852-1h10.296c.47 0 .852.447.852 1 0 .553-.381 1-.852 1z"
      ></path>
    </svg>
  );
};

const TopBar = ({
  className,
  onMobileNavOpen,
  isOpen,
  setOpen,
  match,
  ...rest
}: IProps) => {
  const classes = useStyles();

  const classesMapping = [
    classes.navDropdownItemActive0,
    classes.navDropdownItemActive1,
    classes.navDropdownItemActive2,
    classes.navDropdownItemActive3,
    classes.navDropdownItemActive4,
    classes.navDropdownItemActive5,
    classes.navDropdownItemActive6,
  ];

  const { assets } = useWIAssetService();

  const handleDrawerOpen = () => {
    onMobileNavOpen();
  };

  const handleClick = (e: any, href: string, target: string) => {
    try {
      const fullUrl = new URL(href);

      if (fullUrl) {
        e.preventDefault();
      }
    } catch {
      return;
    }

    if (target === "_blank") {
      window.open(href, "_blank");
      return;
    }

    window.location.href = href;
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <Logo />

          <Hidden mdUp>
            <MenuButton onClick={handleDrawerOpen} />
          </Hidden>
        </div>

        <Hidden smDown>
          <MultiAssestAccess assestsAccess={assets} />
        </Hidden>

        <Hidden smDown>
          <div className={classes.navContainer}>
            {navItems.map((navItem, index) => (
              <>
                <Body4
                  key={navItem.href}
                  variant="bold"
                  onClick={(e: any) =>
                    handleClick(e, navItem.href, navItem.target)
                  }
                  color={
                    navItem.title === "Hedge Fund Alert" ? "#9B75FF" : undefined
                  }
                  className={clsx(classes.navItem, {
                    [classes.navItemActive]: index === 0,
                  })}
                >
                  {navItem.title}
                </Body4>
                {index === 0 && (
                  <StyledUl className={classes.navDropdown}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="17"
                      viewBox="0 0 22 17"
                      fill="none"
                      className={classes.topIconUp}
                    >
                      <path
                        d="M7.69259 2.50122C8.97578 -0.102481 12.6883 -0.102478 13.9715 2.50122L27.4593 29.8691C28.6056 32.195 26.9129 34.9163 24.3199 34.9163H-2.65582C-5.24887 34.9163 -6.94156 32.195 -5.79526 29.8691L7.69259 2.50122Z"
                        fill="#0C0B0D"
                        stroke="#434248"
                      />
                    </svg>
                    {dropdownItems.map((item, i) => (
                      <li
                        className={clsx(
                          classes.navDropdownItem,
                          classesMapping[i]
                        )}
                      >
                        <Link
                          href={item.href}
                          target={item.target}
                          className={classes.DropdownItemLink}
                        >
                          <Box className={classes.navDropdownItemImage}>
                            {React.createElement(item.icon, {
                              className: classes.DropdownIcon,
                            })}
                          </Box>
                          <Box className={classes.navDropdownItemText}>
                            <Typography
                              component="h6"
                              variant="h6"
                              className={classes.DropdownTitle}
                            >
                              {item.title}
                            </Typography>
                            <Typography style={{ fontSize: "0.875rem" }}>
                              {item.description}
                            </Typography>
                          </Box>
                        </Link>
                      </li>
                    ))}
                  </StyledUl>
                )}
              </>
            ))}
          </div>
        </Hidden>

        <Box flexGrow={1} />

        <AccountDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
