import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";

import Container from "@material-ui/core/Container";
import Link from "found/Link";
import useRouter from "found/useRouter";

import items from "./tab-bar-items";
import Body4 from "common/Typography/Body4";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  tab: {
    "&.selected": {
      color: theme.palette.primary.main,
      borderBottom: "2px solid",
      borderColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const router = useRouter();
  const pathname = router?.match?.location?.pathname || "";

  return (
    <Container className={classes.root}>
      {items.map((item, index) => (
        <Tab
          key={index}
          value={
            pathname === "/articles/search" && index === 0
              ? "/articles/search"
              : item.href
          }
          label={
            <Body4 variant="bold" color="inherit">
              {item.title}
            </Body4>
          }
          component={Link}
          to={item.href}
          className={clsx(classes.tab, {
            selected: pathname.includes(item.href),
          })}
        />
      ))}
    </Container>
  );
}
