/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PublicationAdminEmailqueueQueryVariables = {};
export type PublicationAdminEmailqueueQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"QlistAdmin_emailqueues">;
};
export type PublicationAdminEmailqueueQuery = {
    readonly response: PublicationAdminEmailqueueQueryResponse;
    readonly variables: PublicationAdminEmailqueueQueryVariables;
};



/*
query PublicationAdminEmailqueueQuery {
  ...QlistAdmin_emailqueues
}

fragment QlistAdmin_emailqueues on Query {
  emailqueues(first: 10) {
    edges {
      node {
        id
        type
        subject
        createdAt
        publishedDate
        status
        publication {
          id
          title
          fileName
        }
        totalEmails
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PublicationAdminEmailqueueQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "QlistAdmin_emailqueues"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PublicationAdminEmailqueueQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "EmailqueueConnection",
        "kind": "LinkedField",
        "name": "emailqueues",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailqueueEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Emailqueue",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subject",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Publication",
                    "kind": "LinkedField",
                    "name": "publication",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalEmails",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "emailqueues(first:10)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "QlistAdmin_emailqueues",
        "kind": "LinkedHandle",
        "name": "emailqueues"
      }
    ]
  },
  "params": {
    "cacheID": "5dace93c4a2bc7259392d6b033ac7f73",
    "id": null,
    "metadata": {},
    "name": "PublicationAdminEmailqueueQuery",
    "operationKind": "query",
    "text": "query PublicationAdminEmailqueueQuery {\n  ...QlistAdmin_emailqueues\n}\n\nfragment QlistAdmin_emailqueues on Query {\n  emailqueues(first: 10) {\n    edges {\n      node {\n        id\n        type\n        subject\n        createdAt\n        publishedDate\n        status\n        publication {\n          id\n          title\n          fileName\n        }\n        totalEmails\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '73d0d02f99eefe2899e4f001cfab39b3';
export default node;
