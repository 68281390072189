import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useOutsideClick } from "rooks";
import clsx from "clsx";

import Body4 from "common/Typography/Body4";

export const DEFAULT_ACTIVE_SLUG = "hfm";
export const DEFAULT_ACTIVE_NAME = "Hedge Funds";

export interface AssestAccess {
  label: string;
  shorthandLabel: string;
  href: string;
  slug: string;
  active: boolean;
}

interface IProps {
  assestsAccess: AssestAccess[];
}

interface IAvatarProps {
  children?: React.ReactNode;
}

interface IArrowIconProps {
  rotate?: number;
}

interface IStyleProps {
  dropdownOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",

      "& [data-component=typography-body-4], & [data-component=multi-assest-access-avatar]":
        {
          userSelect: "none",
        },
    },
    selectorContainer: {
      display: "flex",
      flexFlow: "column",
      alignItems: "flex-start",
      borderRadius: "0.25rem",
      padding: "0px",
      color: "rgb(255, 255, 255)",
      background: "unset",
      border: "0.0625rem solid rgb(213, 213, 214)",
      marginLeft: "1rem",
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    selector: ({ dropdownOpen }: IStyleProps) => {
      return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.25rem 0.5rem",
        width: "10.4375rem",
        height: "2rem",
        minWidth: "7.1875rem",

        background: "unset",

        flex: "none",
        order: 0,
        flexGrow: 0,
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "#434248",
        },
      };
    },

    selectorMain: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",

      "& > :first-child": {
        marginRight: "10px",
      },
    },

    separateLineContainer: {
      height: "1px",
      width: "10.4375rem",
      marginLeft: "1rem",
      borderLeft: "1px solid #D5D5D6",
      borderRight: "1px solid #D5D5D6",
    },

    separateLineItem: {
      height: "1px",
      width: "100%",
      backgroundColor: "#63626A",
    },

    dropdown: {
      position: "absolute",
      marginLeft: "1rem",
      width: "169px",
      backgroundColor: "#26262C",
      border: "1px solid #D5D5D6",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      borderTop: "none",
      overflow: "hidden",
    },

    dropdownItem: {
      padding: "0.25rem 0.5rem",
      width: "10.4375rem",
      height: "2rem",
      minWidth: "7.1875rem",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      background: "unset",
      flex: "none",
      order: 0,
      flexGrow: 0,
      cursor: "pointer",
      marginBottom: "4px",

      "&:hover": {
        backgroundColor: "#434248",

        "& > [data-component=multi-assest-access-avatar]": {
          backgroundColor: "#26262C",
        },
      },

      "& > :first-child": {
        marginRight: "10px",
      },

      "&:last-child": {
        marginBottom: 0,
      },
    },

    dropdownItemActive: {
      backgroundColor: "#434248",

      "& > [data-component=multi-assest-access-avatar]": {
        backgroundColor: "#26262C",
      },
    },
  })
);

const useAvatarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "24px",
      height: "24px",
      fontSize: "13px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#FFFFFF",
      backgroundColor: "#63626A",
      borderRadius: "50%",
    },
  })
);

const Avatar = ({ children }: IAvatarProps): JSX.Element => {
  const classes = useAvatarStyles();

  return (
    <div data-component="multi-assest-access-avatar" className={classes.root}>
      {children}
    </div>
  );
};

const ArrowIcon = ({ rotate = 0 }: IArrowIconProps): JSX.Element => {
  return (
    <svg
      data-testid="svg-icon"
      fill="#ffffff"
      viewBox="0 0 16 16"
      width="1rem"
      height="1rem"
    >
      <path
        d="M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z"
        fill-rule="evenodd"
      ></path>
    </svg>
  );
};

const MultiAssestAccess = ({ assestsAccess }: IProps): JSX.Element => {
  const containerEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ dropdownOpen: open } as IStyleProps);

  const handleOpenDropdown = () => {
    setOpen(true);
  };

  const handleCloseDropdown = () => {
    setOpen(false);
  };

  const handleToogleDropdown = () => {
    if (open) {
      handleCloseDropdown();
    } else {
      handleOpenDropdown();
    }
  };

  const handleGoingToAsset = (href: string) => {
    window.location.href = href;
  };

  useOutsideClick(containerEl, handleCloseDropdown);

  const defaultSelection = React.useMemo(() => {
    return assestsAccess.find((assest) => assest.slug === DEFAULT_ACTIVE_SLUG);
  }, [assestsAccess]);

  return (
    <div
      data-component="multi-assest-access"
      ref={containerEl}
      className={classes.root}
    >
      <div className={classes.selectorContainer}>
        <div className={classes.selector} onClick={handleToogleDropdown}>
          <div className={classes.selectorMain}>
            <Body4>{defaultSelection?.label}</Body4>
          </div>

          <ArrowIcon rotate={open ? 180 : 0} />
        </div>
      </div>

      {/* {open && (
        <div className={classes.separateLineContainer}>
          <div className={classes.separateLineItem} />
        </div>
      )} */}

      {open && (
        <div className={classes.dropdown}>
          {assestsAccess.map((assest, index) => (
            <div
              key={`assest-access-${index}`}
              className={clsx(
                classes.dropdownItem,
                assest.active ? classes.dropdownItemActive : null
              )}
              onClick={() => handleGoingToAsset(assest.href)}
            >
              <Body4>{assest.label}</Body4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiAssestAccess;
