import React from "react";
// import useRouter from "found/useRouter";

const baseRootUrl = process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC;

const Logo = (): JSX.Element => {
  // const { router } = useRouter();

  const handleClick = () => {
    // router.push("/publications");
    window.location.href = `${baseRootUrl}/hfm/now`;
  };

  return (
    <svg
      onClick={handleClick}
      id="Layer_1"
      data-component="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 428.91 259.05"
      height="2.1875rem"
    >
      <path
        d="M350.27,156c-16,0-26.21,12-26.21,25.91,0,14.8,10.17,26.52,26.21,26.52,15.73,0,26.22-11.72,26.22-26.52C376.49,168,366,156,350.27,156"
        fill="#ffffff"
      ></path>
      <path
        d="M347.16,52.43h-57V64.77c19.74,0,24.67,5.85,17.27,27.44l-29.6,86.66L232.53,52.43H213.1L170.85,178.56l-29.6-86.35c-7.4-21.59-2.47-27.44,17.27-27.44V52.43H52.43V64.77c12.95,0,26.21,5.85,33.61,27.44l39.48,114.41h54.89l27.76-82,24.67,82H288L326.9,92.21c1.6-4.65,3.1-8.68,4.64-12.15.24-.54.48-1.06.72-1.57a19.7,19.7,0,0,1,17.65-11.14V52.43Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

export default Logo;
