/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserAgentViewQueryVariables = {
    id: string;
};
export type UserAgentViewQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"ViewAgentUser_data">;
    };
};
export type UserAgentViewQuery = {
    readonly response: UserAgentViewQueryResponse;
    readonly variables: UserAgentViewQueryVariables;
};



/*
query UserAgentViewQuery(
  $id: ID!
) {
  user(id: $id) {
    ...ViewAgentUser_data
    id
  }
}

fragment ViewAgentUser_data on User {
  id
  email
  firstName
  lastName
  company {
    id
    name
    type
  }
  role
  phone
  status
  emailConfirmed
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAgentViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ViewAgentUser_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserAgentViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailConfirmed",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08911f261af5ce11af2fac9af055358f",
    "id": null,
    "metadata": {},
    "name": "UserAgentViewQuery",
    "operationKind": "query",
    "text": "query UserAgentViewQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    ...ViewAgentUser_data\n    id\n  }\n}\n\nfragment ViewAgentUser_data on User {\n  id\n  email\n  firstName\n  lastName\n  company {\n    id\n    name\n    type\n  }\n  role\n  phone\n  status\n  emailConfirmed\n}\n"
  }
};
})();
(node as any).hash = '466b46370f7d47cab6f11cadc20911d8';
export default node;
