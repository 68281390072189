/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardAdminQueryVariables = {};
export type DashboardAdminQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Dashboard_data">;
};
export type DashboardAdminQuery = {
    readonly response: DashboardAdminQueryResponse;
    readonly variables: DashboardAdminQueryVariables;
};



/*
query DashboardAdminQuery {
  ...Dashboard_data
}

fragment Dashboard_data on Query {
  companyStats {
    data {
      label
      count
    }
  }
  companyDailyStats {
    data {
      label
      active
      inactive
      total
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardAdminQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Dashboard_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardAdminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyStats",
        "kind": "LinkedField",
        "name": "companyStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyStat",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyDailyStats",
        "kind": "LinkedField",
        "name": "companyDailyStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyDailyStat",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inactive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d5dfe3de83f55dbd0ee4d9473436c63",
    "id": null,
    "metadata": {},
    "name": "DashboardAdminQuery",
    "operationKind": "query",
    "text": "query DashboardAdminQuery {\n  ...Dashboard_data\n}\n\nfragment Dashboard_data on Query {\n  companyStats {\n    data {\n      label\n      count\n    }\n  }\n  companyDailyStats {\n    data {\n      label\n      active\n      inactive\n      total\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '11c6a3f89cf566d797fac4f8309c7a36';
export default node;
