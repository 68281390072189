import React from "react";
import useRouter from "found/useRouter";

interface BannerInformation {
  heading: string;
  summary: string;
}

const bannerInformations: Record<string, BannerInformation> = {
  "/publications": {
    heading: "Newsletters",
    summary: `Access every weekly issue of Hedge Fund Alert going back to the year 2000, or search our database of more than 25,000 published articles.`,
  },
  "/rankings": {
    heading: "Rankings",
    summary: `HFA's annual rankings of hedge fund managers and key service providers, based on filings by SEC-registered fund management firms.`,
  },
  "/market-participants": {
    heading: "Market Participants",
    summary: `HFA's exclusive analyses of the key players in the US hedge fundsector`,
  },
  "/special-features": {
    heading: "Special Features",
    summary: `Roundups of industry developments and exclusive analyses published by Hedge Fund Alert throughout the year`,
  },
  "/events": {
    heading: "Events",
    summary: `Upcoming conferences and events`,
  },
};

const useBannerInformation = () => {
  const [information, setInformation] = React.useState<BannerInformation>({
    heading: "",
    summary: "",
  });
  const { match } = useRouter();

  const handleGetInformation = React.useCallback(() => {
    let pathname = match.location.pathname;

    if (pathname[pathname.length - 1] === "/") {
      pathname = pathname.slice(0, -1);
    }

    if (pathname.includes("/publications/download")) {
      setInformation(bannerInformations["/publications"]);
      return;
    }

    bannerInformations?.[pathname] &&
      setInformation(bannerInformations?.[pathname]);
  }, [match.location]);

  React.useEffect(() => {
    handleGetInformation();
  }, [handleGetInformation]);

  return {
    information,
  };
};

export default useBannerInformation;
