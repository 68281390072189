import { Suspense } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { getCookieConsentValue } from "react-cookie-consent";

import BrowserProtocol from "farce/BrowserProtocol";
import queryMiddleware from "farce/queryMiddleware";
import createFarceRouter from "found/createFarceRouter";
import { Resolver } from "found-relay";

import { RelayEnvironmentProvider } from "relay-hooks";

import * as PDFJS from "pdfjs-dist";

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import routes from "../routes";
import defaultTheme from "../theme";
import Spinner from "./SpinnerFacebook";
// import ErrorPage from 'pages/ErrorPage';
import { useWindowSize } from "./hooks/useWindowsize";
import ErrorBoundary from "./ErrorBoundary";

// Contexts
import ViewportContext from "./ViewportContext";
import { AuthProvider } from "./hooks/AuthProvider";
import initRequest from "common/request";

import RelayEnvironment from "common/Relay/RelayEnvironment";
import NotFound from "common/NotFound";
import Head from "common/Head";

import GaRouterMiddleware from "../util/GaRouterMiddleware";
import RouterMiddleware from "../util/RouterMiddleware";
import { COOKIE_CONSENT_COOKIE_NAME } from "./stores/constants";

const acceptConsentCookie =
  getCookieConsentValue(COOKIE_CONSENT_COOKIE_NAME) === true ||
  getCookieConsentValue(COOKIE_CONSENT_COOKIE_NAME) === "true";

if (acceptConsentCookie) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  };

  TagManager.initialize(tagManagerArgs);

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
} else {
  window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`] = true;
}

initRequest();

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware, GaRouterMiddleware, RouterMiddleware],

  routeConfig: routes,

  renderError: ({ error }) => (
    <div>{error.status === 404 ? <NotFound /> : "Error"}</div>
  ),

  //   renderError: (
  //     { error }, // eslint-disable-line react/prop-types
  //   ) => <ErrorPage error={error} />,
});

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        backgroundColor: "#fff",
        borderRadius: "4px",
        marginTop: "16px",
        marginBottom: "8px",
      },
      container: {
        display: "flex",
        flexDirection: "column",
        margin: "0",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      },
      editor: {
        backgroundColor: "#fff",
        padding: "8px",
        height: "200px",
        maxHeight: "200px",
        overflow: "auto",
      },
      textLink: {
        color: "#a0228e",
      },
      toolbar: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      },
      placeHolder: {
        backgroundColor: "#fff",
        paddingLeft: 20,
        width: "inherit",
        position: "relative",
        // top: "20px"
      },
      anchorLink: {
        color: "#333333",
        textDecoration: "underline",
      },
    },
  },
});

const App = (props) => {
  const { width, height } = useWindowSize();
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <MuiThemeProvider theme={defaultTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <Head />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AuthProvider accessTokenKey="token">
            <ViewportContext.Provider value={{ width, height }}>
              <ErrorBoundary>
                <Suspense fallback={<Spinner />}>
                  <Router resolver={new Resolver(RelayEnvironment)} />
                </Suspense>
              </ErrorBoundary>
            </ViewportContext.Provider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </RelayEnvironmentProvider>
  );
};

export default App;
