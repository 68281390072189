const baseRootUrl = process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC;

const items = [
  {
    href: `${baseRootUrl}/hfm/insights`,
    icon: null,
    title: "Insights",
    target: "_self",
    defaultActive: false,
  },
  {
    href: `${baseRootUrl}/hfm/discover`,
    icon: null,
    title: "Explore",
    target: "_self",
    defaultActive: false,
  },
  {
    href: `${baseRootUrl}/hfm/performance`,
    icon: null,
    title: "Performance",
    target: "_self",
    defaultActive: false,
  },
  {
    href: "/publications",
    icon: null,
    title: "Hedge Fund Alert",
    target: "_self",
    defaultActive: true,
  },
  {
    href: `https://www.withintelligence.com/events-calendar`,
    icon: null,
    title: "Events",
    target: "_blank",
    defaultActive: false,
  },
];

export default items;
