/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserAgentEditQueryVariables = {
    id: string;
};
export type UserAgentEditQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"EditAgentUser_data">;
    };
};
export type UserAgentEditQuery = {
    readonly response: UserAgentEditQueryResponse;
    readonly variables: UserAgentEditQueryVariables;
};



/*
query UserAgentEditQuery(
  $id: ID!
) {
  user(id: $id) {
    ...EditAgentUser_data
    id
  }
}

fragment EditAgentUser_data on User {
  id
  email
  firstName
  lastName
  companyName
  emailOptOut
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAgentEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditAgentUser_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserAgentEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailOptOut",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9e1b06b5af4e5c0a4cba90b897cda3c",
    "id": null,
    "metadata": {},
    "name": "UserAgentEditQuery",
    "operationKind": "query",
    "text": "query UserAgentEditQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    ...EditAgentUser_data\n    id\n  }\n}\n\nfragment EditAgentUser_data on User {\n  id\n  email\n  firstName\n  lastName\n  companyName\n  emailOptOut\n}\n"
  }
};
})();
(node as any).hash = '4976a4cc1d11ed7609443d449048e4bc';
export default node;
