import { ActionTypes } from "farce";

declare global {
  interface Window {
    routerStore?: any;
  }
}

const RouterMiddleware = (store: any) => (next: any) => (action: any) => {
  if (typeof window !== "undefined" && !window.routerStore) {
    window.routerStore = store;
  }

  if (
    action?.type === ActionTypes.NAVIGATE &&
    action?.payload?.pathname === "/user/login" &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/user/forgotPassword/message"
  ) {
    if (window.location.pathname === "/user/login") {
      action.payload.search = "";
    } else {
      if (action.payload.search) {
        const params = new URLSearchParams(action.payload.search);
        const paramClone = new URLSearchParams(action.payload.search);
        paramClone.delete("redirect");
        params.set(
          "redirect",
          encodeURIComponent(
            btoa(window.location.pathname + "?" + paramClone.toString())
          )
        );
        action.payload.search = `?${params.toString()}`;
      } else {
        const params = new URLSearchParams(action.payload.search);
        params.delete("expired");
        action.payload.search = `?redirect=${encodeURIComponent(
          btoa(window.location.pathname + "?" + params.toString())
        )}`;
      }
    }
  }

  return next(action);
};

export default RouterMiddleware;
