import { lazy } from "react";
import graphql from "babel-plugin-relay/macro";
// import RedirectException from "found/RedirectException";

import Layout from "common/Layouts/Admin";
import { isLoggedin } from "common/hooks/useAuth";
import RedirectNonAdmin, { redirectUrl } from "common/RedirectNonAdmin";

const routes = [
  {
    path: "/admin/dashboard",
    query: graphql`
      query DashboardAdminQuery {
        ...Dashboard_data
      }
    `,
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./Dashboard")),
    render: ({ Component, resolving, props }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = redirectUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout>
          <Component data={props ? props : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/loading",
    query: graphql`
      query DashboardLoadingAdminQuery {
        ...Loading_data
      }
    `,
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'loader' */ "./Loading")),
    render: ({ Component, resolving, props }) => {
      return <Component data={props ? props : null} />;
    },
  },
];

export default routes;
