export const statuses = [
  {
    value: "current",
    label: "Active",
  },
  {
    value: "trialist",
    label: "Trialist",
  },
  {
    value: "suspended",
    label: "Suspended",
  },
];

export const statusArray = () => {
  const result: Array<string> = [];
  statuses.map((item: any) => {
    result[item.value] = item.label;
  });
  return result;
};

export const roles = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "user",
    label: "User",
  },
];
