import Axios from "axios";
import useAxios, { configure } from "axios-hooks";
import Cookies from "js-cookie";
import { openAuthPopup } from "./AuthPopup";
import { logOut } from "./hooks/useAuth";

const wiAuthCookieName = process.env.REACT_APP_WI_AUTH_COOKIE_NAME;
const loginUrl = process.env.REACT_APP_LOGIN_LINK ?? "";

export default function initRequest() {
  const baseURL = process.env.REACT_APP_REST_API_DOMAIN;

  const axios = Axios.create({
    baseURL,
  });

  axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");

      if (token) {
        config.headers = {
          "x-access-token": token,
        };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      return Promise.reject(error);
    }
  );

  configure({
    axios,
    cache: false,
  });
}

export const useRequest = useAxios;

export const articleRequest = () => {
  const baseURL = process.env.REACT_APP_REST_API_DOMAIN;

  const axios = Axios.create({
    baseURL,
  });

  axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");

      if (token) {
        config.headers = {
          "x-access-token": token,
        };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      return Promise.reject(error);
    }
  );

  return axios;
};

export const backEndRequest = () => {
  const baseURL = process.env.REACT_APP_API_DOMAIN;

  const axios = Axios.create({
    baseURL,
  });

  axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
      const wiAccessToken = Cookies.get(wiAuthCookieName);

      config.headers = {
        "hfa-access-token": wiAccessToken,
      };

      if (token) {
        config.headers["authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.data?.message === "authWIToken has wrong decoded") {
        window.location.href = `${loginUrl}?redirect=${window.location.href}`;
      }

      if (
        error.response?.data?.message === "authWIToken failed check state!" ||
        error.response?.data?.message ===
          "WI Token and Auth Token does not match!" ||
        error.response?.data?.message === "authWIToken token not found."
      ) {
        localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
        localStorage.removeItem("user");

        if (
          error.response?.data?.message ===
          "WI Token and Auth Token does not match!"
        ) {
          return window.location.reload();
        }

        console.log("Request Log out");

        logOut();
      }

      if (error.response.status === 401) {
        setTimeout(() => {
          openAuthPopup();
        }, 1000);
      }

      return Promise.reject(error);
    }
  );

  return axios;
};
