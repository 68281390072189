/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuthLogAdminViewQueryVariables = {
    id: string;
};
export type AuthLogAdminViewQueryResponse = {
    readonly authLog: {
        readonly " $fragmentRefs": FragmentRefs<"ViewAdminAuthLog_data">;
    };
};
export type AuthLogAdminViewQuery = {
    readonly response: AuthLogAdminViewQueryResponse;
    readonly variables: AuthLogAdminViewQueryVariables;
};



/*
query AuthLogAdminViewQuery(
  $id: ID!
) {
  authLog(id: $id) {
    ...ViewAdminAuthLog_data
    id
  }
}

fragment ViewAdminAuthLog_data on Auth {
  id
  user {
    id
    firstName
    lastName
  }
  action
  result
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthLogAdminViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Auth",
        "kind": "LinkedField",
        "name": "authLog",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ViewAdminAuthLog_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthLogAdminViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Auth",
        "kind": "LinkedField",
        "name": "authLog",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "result",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4006f08c420336489f4731c0f46e4b3a",
    "id": null,
    "metadata": {},
    "name": "AuthLogAdminViewQuery",
    "operationKind": "query",
    "text": "query AuthLogAdminViewQuery(\n  $id: ID!\n) {\n  authLog(id: $id) {\n    ...ViewAdminAuthLog_data\n    id\n  }\n}\n\nfragment ViewAdminAuthLog_data on Auth {\n  id\n  user {\n    id\n    firstName\n    lastName\n  }\n  action\n  result\n  createdAt\n}\n"
  }
};
})();
(node as any).hash = 'de99cfaa9e0a33988b1e7f16abfe76ea';
export default node;
