import React from "react";
import useRouter from "found/useRouter";
import {
  AssestAccess,
  DEFAULT_ACTIVE_SLUG,
  DEFAULT_ACTIVE_NAME,
} from "common/Layouts/Authenticated/MultiAssestAccess";

const baseUrl = process.env.REACT_APP_BASE_DOMAIN_PUBLIC ?? "";
const baseRootUrl = process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC ?? "";
const storageKey = "wi-assets";

interface PackageQuery {
  name: string;
  slug: string;
}

const useWIAssetService = () => {
  const [assets, setAssets] = React.useState<AssestAccess[]>([]);
  const assetLength = assets.length;
  const { match } = useRouter();

  const getHref = (slug: string): string => {
    return `${baseRootUrl}/${slug}/now`;
  };

  const getShortHandLabel = (label: string): string => {
    let shorthand = "";

    label.split(" ").forEach((labelSplited: string) => {
      shorthand += labelSplited.charAt(0);
    });

    return shorthand.toUpperCase();
  };

  const defaultAssetAccessItem = {
    label: DEFAULT_ACTIVE_NAME,
    href: getHref(DEFAULT_ACTIVE_SLUG),
    shorthandLabel: getShortHandLabel(DEFAULT_ACTIVE_NAME),
    active: true,
    slug: DEFAULT_ACTIVE_SLUG,
  };

  const handleGetPackageQuery = React.useCallback(() => {
    if (assetLength) {
      return;
    }

    const packageQuery = match.location.query?.packages ?? "";

    let assetStorgared: AssestAccess[] = [];

    try {
      assetStorgared = JSON.parse(
        localStorage.getItem(storageKey) ?? "[]"
      ) as AssestAccess[];
    } catch (error) { }

    if (!packageQuery) {
      setAssets(assetStorgared.length ? assetStorgared : [defaultAssetAccessItem]);
      return;
    }

    let packageQueryParsers: PackageQuery[] = [];

    try {
      packageQueryParsers = JSON.parse(atob(packageQuery)) as PackageQuery[];
    } catch (error) { }

    assetStorgared = [];

    packageQueryParsers.forEach((packageQueryParser: PackageQuery) => {
      assetStorgared.push({
        label: packageQueryParser.name,
        href: getHref(packageQueryParser.slug),
        shorthandLabel: getShortHandLabel(packageQueryParser.name),
        active: packageQueryParser.slug === DEFAULT_ACTIVE_SLUG,
        slug: packageQueryParser.slug,
      });
    });

    setAssets(assetStorgared.length ? assetStorgared : [defaultAssetAccessItem]);
    localStorage.setItem(storageKey, JSON.stringify(assetStorgared));
  }, [assetLength, match.location.query?.packages]);

  React.useEffect(() => {
    handleGetPackageQuery();
  }, [handleGetPackageQuery]);

  return {
    assets,
  };
};

export default useWIAssetService;
