import ReactGA from "react-ga";
import { ActionTypes } from "farce";

const GaRouterMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action && action.type === ActionTypes.UPDATE_LOCATION) {
    const pathName = action?.payload?.pathname!;
    ReactGA.pageview(pathName);
  }

  return next(action);
};

export default GaRouterMiddleware;
