import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

const BASE_ROOT_DOMAIN_PUBLIC =
  process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC || "";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  textNormal: {
    color: "#263238",
  },
});

interface Iprops {
  isOpen?: boolean;
  setOpen: any;
}

export default function NavBar({ isOpen, setOpen }: Iprops) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    window.location.href = `${BASE_ROOT_DOMAIN_PUBLIC}/login`;
  };

  const link = useMemo(() => {
    const mailSubject = "Hedge Fund Alert Customer Query";
    return `mailto:support@hedgefundalert.com?subject=${encodeURIComponent(
      mailSubject
    )}`;
  }, []);

  const list = () => (
    <div className={classes.list} role="presentation" onKeyDown={handleClose}>
      <List>
        <ListItem button onClick={handleLogin}>
          <ListItemIcon>
            <FirstPageIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>

        <ListItem component="a" href={link}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText className={classes.textNormal} primary="Help" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <Drawer anchor="left" open={isOpen} onClose={handleClose}>
      {list()}
    </Drawer>
  );
}
