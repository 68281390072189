/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type userResetPasswordQueryVariables = {
    token: string;
};
export type userResetPasswordQueryResponse = {
    readonly verifyToken: {
        readonly " $fragmentRefs": FragmentRefs<"ResetPasswordUser_data">;
    };
};
export type userResetPasswordQuery = {
    readonly response: userResetPasswordQueryResponse;
    readonly variables: userResetPasswordQueryVariables;
};



/*
query userResetPasswordQuery(
  $token: String!
) {
  verifyToken(token: $token) {
    ...ResetPasswordUser_data
  }
}

fragment ResetPasswordUser_data on VerifyTokenPayload {
  user {
    id
    email
    firstName
    lastName
  }
  error
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userResetPasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyTokenPayload",
        "kind": "LinkedField",
        "name": "verifyToken",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ResetPasswordUser_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userResetPasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyTokenPayload",
        "kind": "LinkedField",
        "name": "verifyToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c5f07612c7bb1fdcb1852124747a776c",
    "id": null,
    "metadata": {},
    "name": "userResetPasswordQuery",
    "operationKind": "query",
    "text": "query userResetPasswordQuery(\n  $token: String!\n) {\n  verifyToken(token: $token) {\n    ...ResetPasswordUser_data\n  }\n}\n\nfragment ResetPasswordUser_data on VerifyTokenPayload {\n  user {\n    id\n    email\n    firstName\n    lastName\n  }\n  error\n}\n"
  }
};
})();
(node as any).hash = 'bd7b6a8853377516fee07558ffbf4d8a';
export default node;
