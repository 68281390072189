import { lazy } from "react";
import graphql from "babel-plugin-relay/macro";
import RedirectException from "found/RedirectException";
import { Provider } from "react-redux";
//import HttpError from "found/HttpError";

import Layout from "common/Layouts/Authenticated";
import { isLoggedin } from "common/hooks/useAuth";
import store from "common/stores";

const routes = [
  {
    path: "/events",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'authenticated' */ "./List")),
    render: ({ props, Component, error, resolving }) => {
      // if (!isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Provider store={store}>
              <Component />
            </Provider>
          )}
        </Layout>
      );
    },
  },
  {
    path: "/events/download/:id",
    query: graphql`
      query EventsDownloadAuthenticatedQuery($id: ID!) {
        publication(id: $id) {
          ...DownloadAuthenticatedPublication_data
        }
      }
    `,
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() =>
        import(
          /* webpackChunkName: 'authenticated' */ "../Publication/Download"
        )
      ),
    prepareVariables: (params) => ({ id: params.id || "" }),
    render: ({ props, Component, error, resolving }) => {
      // if (resolving && !isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }

      // if (error) {
      //   throw new RedirectException(`/user/login`);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              goBackTo="/events"
              data={props ? props.publication : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
];

export default routes;
