import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

import { FETCH_STATUS } from "common/stores/constants";

const initialState = {
  status: FETCH_STATUS.idle,
  error: null,
  years: [],
  isOpenModalFilter: false,
  publicationActive: null,
  articles: {
    status: FETCH_STATUS.idle,
    error: null,
    data: {
      general: [],
      grapevine: [],
    },
  },
  marketParticipants: {
    status: FETCH_STATUS.idle,
    error: null,
    data: [],
  },
  specialFeatures: {
    status: FETCH_STATUS.idle,
    error: null,
    data: [],
  },
  events: {
    status: FETCH_STATUS.idle,
    error: null,
    data: [],
  },
  rankings: {
    status: FETCH_STATUS.idle,
    error: null,
    topicId: null,
    data: [],
  },
};

export const counterSlice = createSlice({
  name: "authenticated/publication",
  initialState,
  reducers: {
    fetchYear(state, action) {
      // Triggger middlewate to fetch year
    },

    setYear(state, action) {
      state.years = action.payload;
    },

    setYearStatus(state, action) {
      state.status = action.payload;
    },

    toggleActiveYear(state, action) {
      // Triggger middlewate to toggle active year and get publication
    },

    fetchPublicationByTopic(state, action) {
      // Triggger middlewate to toggle active year and get publication
    },

    setActiveYear(state, action) {
      const { year, publications, isActive } = action.payload;
      const yearIndex = state.years.findIndex((item) => item.value === year);

      state.years[yearIndex].isActive = isActive;

      if (Boolean(publications)) {
        state.years[yearIndex].publications.data = publications;
      }
    },

    setsetPublicationYearStatus(state, action) {
      const { year, status } = action.payload;
      const yearIndex = state.years.findIndex((item) => item.value === year);

      state.years[yearIndex].publications.status = status;
    },

    setPublicationActive(state, action) {
      state.publicationActive = action.payload;
    },

    setArticles(state, action) {
      state.articles.data = action.payload;
    },

    setArticleStatus(state, action) {
      state.articles.status = action.payload;
    },

    setArticlesSearch(state, action) {
      state.articlesSearch.data = action.payload;
    },

    setArticleSearchStatus(state, action) {
      state.articlesSearch.status = action.payload;
    },

    setIsOpenModalFilter(state, action) {
      state.isOpenModalFilter = action.payload;
    },

    setMarketParticipants(state, action) {
      state.marketParticipants = {
        ...state.marketParticipants,
        ...action.payload,
      };
    },

    setSpecialFeatures(state, action) {
      state.specialFeatures = {
        ...state.specialFeatures,
        ...action.payload,
      };
    },

    setEvents(state, action) {
      state.events = {
        ...state.events,
        ...action.payload,
      };
    },

    setMarketParticipantStatus(state, action) {
      state.marketParticipants.status = action.payload;
    },

    setSpecialFeaturesStatus(state, action) {
      state.specialFeatures.status = action.payload;
    },

    setEventsStatus(state, action) {
      state.events.status = action.payload;
    },

    setRanking(state, action) {
      state.rankings = {
        ...state.rankings,
        data: action.payload,
      };
    },

    setRankingCurrentTopic(state, action) {
      state.rankings = {
        ...state.rankings,
        topicId: action.payload,
      };
    },

    setRankingStatus(state, action) {
      state.rankings.status = action.payload;
    },

    fetchMarketParticipants(state, action) {},

    fetchSpecialFeatures(state, action) {},

    fetchEvents(state, action) {},
  },
});

// Action creators are generated for each case reducer function
export const actions = counterSlice.actions;

// Selectors
export const selectors = {
  publicationStateSlt: createSelector(
    (state) => state.publication,
    (publication) => publication
  ),

  publicationActiveSlt: createSelector(
    (state) => state.publication.publicationActive,
    (publicationActive) => publicationActive
  ),

  yearsSelector: createSelector((state) => state.publication.years),

  marketParticipantSelector: createSelector(
    (state) => state.publication.marketParticipants,
    (articlesSearch) => articlesSearch
  ),

  specialFeaturesSelector: createSelector(
    (state) => state.publication.specialFeatures,
    (articlesSearch) => articlesSearch
  ),

  eventsSelector: createSelector(
    (state) => state.publication.events,
    (articlesSearch) => articlesSearch
  ),

  rankingSelector: createSelector(
    (state) => state.publication.rankings,
    (articlesSearch) => articlesSearch
  ),
};

export default counterSlice.reducer;
