import moment from "moment";
import "moment/min/locales";
import { Parser } from "json2csv";
import { backEndRequest } from "common/request";
import { statusArray } from "admin/User/Helper";

export const getLocaleDateFormat = () => {
  if (typeof window === "undefined") {
    return moment.localeData("en-gb").longDateFormat("L");
  }
  const locale =
    window.navigator.userLanguage || window.navigator.language || "en-gb";
  return moment.localeData(locale).longDateFormat("LL");
};

export const setGlobalLocaleDateFormat = () => {
  if (typeof window === "undefined") {
    return moment.locale("en-gb");
  }
  const locale = window.navigator.userLanguage || window.navigator.language;
  return moment.locale(locale);
};

export const isValidSalesForceId = (id: string) => {
  const regexSaleForce = /^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/;
  return regexSaleForce.test(id);
};

export const exportUsersToCSV = async () => {
  const statusMapped = statusArray();
  const { data } = await backEndRequest().get("/user");
  const fields = [
    "id",
    "createdAt",
    "updatedAt",
    "isDeleted",
    "email",
    "firstName",
    "lastName",
    "title",
    "department",
    "status",
    "emailOptOut",
    "phone",
    "mobile",
    "companyId",
    "companyName",
    "addressId",
    "country",
    "role",
    {
      label: "Saleforce ID",
      value: "salesforceId",
      default: "",
    },
    "emailConfirmed",
    "isLoggedIn",
    "accountIdOld",
    "contactIdOld",
    "lastLoggedIn",
    "expireTime",
  ];
  const transforms = (item: any) => {
    return {
      ...item,
      createdAt: moment(item.createdAt).toLocaleString(),
      updatedAt: moment(item.updatedAt).toLocaleString(),
      isDeleted: item.isDeleted ? 1 : 0,
      emailConfirmed: item.isDeleted ? 1 : 0,
      isLoggedIn: item.isDeleted ? 1 : 0,
      status: statusMapped[item.status],
      lastLoggedIn: moment(item.lastLoggedIn).isValid()
        ? moment(item.lastLoggedIn).toLocaleString()
        : item.lastLoggedIn,
    };
  };
  const json2csvParser = new Parser({ fields, transforms });
  const csv = json2csvParser.parse(data);
  const exportedFilenmae = "users.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const formatDisplayDate: any = (inputDateTime: string) => {
  if (inputDateTime) {
    return moment(inputDateTime).utc().format("LL");
  }
  return "--";
};

export const emailSubscribeUrl = (): string => {
  const email = "support@hedgefundalert.com";
  const subject = "Hedge Fund Alert New Subscriber Request";
  const body = "";
  return `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
};
