import { lazy } from "react";
import RedirectException from "found/RedirectException";
import { Provider } from "react-redux";
//import HttpError from "found/HttpError";

import Layout from "common/Layouts/Authenticated";
import { isLoggedin } from "common/hooks/useAuth";
import store from "common/stores";

const routes = [
  {
    path: "/articles/search",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'authenticated' */ "./Search")),
    render: ({ props, Component, error, resolving }) => {
      // if (!isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Provider store={store}>
              <Component />
            </Provider>
          )}
        </Layout>
      );
    },
  },
];

export default routes;
