import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FacebookProgress from "./FacebookProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    flexGrow: 1,
  },
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FacebookProgress />
    </div>
  );
}
