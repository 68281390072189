import { ReactComponent as bdc } from "common/images/nav-bdc.svg";
import { ReactComponent as group } from "common/images/nav-group.svg";
import { ReactComponent as home } from "common/images/nav-home.svg";
import { ReactComponent as manager } from "common/images/nav-manager.svg";
import { ReactComponent as mandate } from "common/images/nav-mandate.svg";
import { ReactComponent as reports } from "common/images/nav-reports.svg";
import { ReactComponent as research } from "common/images/nav-research.svg";
const baseRootUrl = process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC;
const items = [
  {
    href: `${baseRootUrl}/hfm/now`,
    icon: home,
    title: "Your feed",
    description:
      "Intel and updates on the topics and profiles you're following",
    target: "_self",
    defaultActive: false,
    hoverColor: "#AD86FB",
  },
  {
    href: `${baseRootUrl}/hfm/now/asset-raising?p=34450`,
    icon: mandate,
    title: "Asset Raising",
    description: "Intel on key people, strategic plans and allocation updates",
    target: "_self",
    defaultActive: false,
    hoverColor: "#56B28B",
  },
  {
    href: `${baseRootUrl}/hfm/now/funds?p=34455`,
    icon: manager,
    title: "Fund Managers",
    description: "Fund launches, closures and people moves",
    target: "_self",
    defaultActive: false,
    hoverColor: "#2BA7C2",
  },
  {
    href: `${baseRootUrl}/hfm/now/performance-markets?p=34459`,
    icon: group,
    title: "Performance & Markets",
    description: "Which strategies are performing best and why",
    target: "_self",
    defaultActive: false,
    hoverColor: "#797BE1",
  },
  {
    href: `${baseRootUrl}/hfm/now/billion-dollar-club?p=34464`,
    icon: bdc,
    title: "Billion Dollar Club",
    description:
      "Curated content relating to the billion dollar hedge fund managers",
    target: "_self",
    defaultActive: false,
    hoverColor: "#CFB67F",
  },
  {
    href: `${baseRootUrl}/hfm/now/reports?p=34467`,
    icon: reports,
    title: "Reports",
    description: "Industry analytics",
    target: "_self",
    defaultActive: false,
    hoverColor: "#00D355",
  },
  {
    href: `${baseRootUrl}/hfm/now/research?p=34472`,
    icon: research,
    title: "Research",
    description: "Our market insight",
    target: "_self",
    defaultActive: false,
    hoverColor: "#F27171",
  },
];
export default items;
