import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import rootMiddleware from "./middleware";

import publicationReducer from "./authenticated/publication";

const nodeEnv = process.env.REACT_APP_NODE_ENV;

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

if (nodeEnv !== "production") {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: {
    publication: publicationReducer,
  },
  devTools: nodeEnv !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

sagaMiddleware.run(rootMiddleware);

export default store;
