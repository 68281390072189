//import React from 'react';
import RouterLink from "found/Link";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: "flex-start",
      letterSpacing: 0,
      padding: "10px 20px",
      textTransform: "none",
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    title: {
      marginRight: "auto",
    },
    active: {
      color: theme.palette.primary.main,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& $icon": {
        color: theme.palette.primary.main,
      },
    },
  })
);

interface IProps {
  className?: string;
  href: string;
  icon: any;
  title: string;
}

const NavItem = ({ className, href, icon: Icon, title, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <ListItem
      key={title}
      className={clsx(classes.item, className)}
      disableGutters
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <ListItemIcon>
            <Tooltip title={title}>
              <Icon className={classes.icon} />
            </Tooltip>
          </ListItemIcon>
        )}
        <ListItemText className={classes.title} primary={title} />
      </Button>
    </ListItem>
  );
};

export default NavItem;
