import dashboard from "./Dashboard";
import publication from "./Publication";
import ranking from "./Ranking";
import marketParticipants from "./MarketParticipants";
import specialFeatures from "./SpecialFeatures";
import events from "./Events";
import user from "./User";
import article from "./Article";

const routes = [
  ...dashboard,
  ...user,
  ...publication,
  ...ranking,
  ...marketParticipants,
  ...specialFeatures,
  ...events,
  ...article,
];

export default routes;
