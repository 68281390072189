import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "clsx";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Button from '@material-ui/core/Button';
// import Hidden from '@material-ui/core/Hidden';
// import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import RouteLink from "found/Link";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
// import GleezIcon from "@material-ui/icons/AllInclusive";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Link from "found/Link";
import logo from "common/images/hfa_logo.svg";
import useAuth from "common/hooks/useAuth";
// import useAuth from 'common/hooks/useAuth';

const BASE_ROOT_DOMAIN_PUBLIC =
  process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC || "";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      appBar: {
        display: "flex",
        border: "0",
        borderRadius: "3px",
        padding: "0.625rem 0",
        color: "#555",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow:
          "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
        transition: "all 150ms ease 0s",
        alignItems: "center",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        position: "relative",
        zIndex: "unset",
        margin: "0px",
        height: "50px",
      },
      appBarMargin: {
        marginBottom: "0px",
      },
      container: {
        minHeight: "40px",
        flex: "1",
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        flexWrap: "nowrap",
        padding: "0px !important",
      },
      absolute: {
        position: "absolute",
        zIndex: "1100",
      },
      fixed: {
        position: "fixed",
        zIndex: "1100",
      },
      icon: {
        marginRight: theme.spacing(2),
        height: "40px",
        marginTop: "11px",
        "@media (max-width: 320px)": {
          height: "30px",
        },
      },
      flex: {
        flex: 1,
        fontSize: "1.5rem",
      },
      title: {
        fontSize: "1.6rem",
        lineHeight: "30px",
        borderRadius: "3px",
        textTransform: "none",
        textDecoration: "none",
        verticalAlign: "top",
        color: "inherit",
        "&:hover,&:focus": {
          color: "inherit",
          background: "transparent",
        },
      },
      primary: {
        backgroundColor: "#9c27b0",
        color: "#FFFFFF",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
      },
      info: {
        backgroundColor: "#00acc1",
        color: "#FFFFFF",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
      },
      success: {
        backgroundColor: "#4caf50",
        color: "#FFFFFF",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
      },
      warning: {
        backgroundColor: "#ff9800",
        color: "#FFFFFF",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
      },
      danger: {
        backgroundColor: "#f44336",
        color: "#FFFFFF",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
      },
      rose: {
        backgroundColor: "#e91e63",
        color: "#FFFFFF",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)",
      },
      transparent: {
        backgroundColor: "transparent !important",
        boxShadow: "none",
        paddingTop: "25px",
        color: "#FFFFFF",
      },
      dark: {
        color: "#FFFFFF",
        backgroundColor: "#212121 !important",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
      },
      white: {
        border: "0",
        padding: "0.625rem 0",
        // marginBottom: '20px',
        color: "#555",
        backgroundColor: "#fff !important",
        boxShadow:
          "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      },
      button: {
        marginLeft: theme.spacing(1),
        //color: theme.palette.common.white,
      },
      bottom: {
        marginBottom: 0,
      },
    }),
  { name: "LayoutHeader" }
);

const Header = (props) => {
  const classes = useStyles();
  //const { isLoggedin, logout } = useAuth();
  const { color, fixed, absolute, heroUnit, appBarMargin } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.bottom]: heroUnit,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.appBarMargin]: appBarMargin,
  });

  const handleDrawerOpen = () => {
    props.setOpen(true);
  };

  const link = useMemo(() => {
    const mailSubject = "Hedge Fund Alert Customer Query";
    return `mailto:support@hedgefundalert.com?subject=${encodeURIComponent(
      mailSubject
    )}`;
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    window.location.href = `${BASE_ROOT_DOMAIN_PUBLIC}/login`;
  };

  return (
    <AppBar className={appBarClasses}>
      <Container>
        <Toolbar className={classes.container}>
          <Link to="/">
            <img
              className={classes.icon}
              src={logo}
              alt={process.env.REACT_APP_SITE_NAME}
            />
          </Link>

          <div>
            {props.isMobile ? (
              <IconButton color="inherit" onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component={"a"}
                  href="/user/login"
                  className={classes.button}
                  onClick={(e) => handleLogin(e)}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={"a"}
                  href={link}
                  className={classes.button}
                >
                  Help
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  heroUnit: PropTypes.bool,
  appBarMargin: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Header.defaultProps = {
  color: "white",
  brand: "",
  heroUnit: false,
  appBarMargin: false,
};

export default memo(Header);
