import { lazy } from "react";
import graphql from "babel-plugin-relay/macro";
import RedirectException from "found/RedirectException";
import HttpError from "found/HttpError";

import Layout from "common/Layouts/Authenticated";
import { isLoggedin, user } from "common/hooks/useAuth";

// console.log(user);
const routes = [
  {
    path: "/users/edit/:id",
    query: graphql`
      query UserAgentEditQuery($id: ID!) {
        user(id: $id) {
          ...EditAgentUser_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'agent' */ "./Edit")),
    prepareVariables: (params) => ({ id: params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      // if (resolving && !isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }

      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.user : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/profile/view",
    query: graphql`
      query UserAgentViewQuery($id: ID!) {
        user(id: $id) {
          ...ViewAgentUser_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'agent' */ "./View")),
    prepareVariables: (params) => ({ id: user().id || params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      // if (resolving && !isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }
      // //   console.log(error);
      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.user : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/users/password/change/:id",
    query: graphql`
      query UserAgentResetpasswordQuery($id: ID!) {
        user(id: $id) {
          ...ResetPasswordAgentUser_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'agent' */ "./ResetPassword")),
    prepareVariables: (params) => ({ id: user().id || params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      // if (resolving && !isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }

      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.user : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
];

export default routes;
