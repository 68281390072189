const BASE_DOMAIN_PUBLIC_URL = process.env.REACT_APP_BASE_DOMAIN_PUBLIC || "";
const BASE_ROOT_DOMAIN_PUBLIC =
  process.env.REACT_APP_BASE_ROOT_DOMAIN_PUBLIC || "";
const BASE_OLD_URL = process.env.REACT_APP_BASE_OLD_DOMAIN_PUBLIC || "";

export const handleRedirectToNewLink = (path = ""): boolean => {
  if (!BASE_OLD_URL.includes(window.location.origin)) {
    return false;
  }

  if (path === "/login") {
    window.location.replace(BASE_ROOT_DOMAIN_PUBLIC + path);
    return true;
  }

  window.location.replace(BASE_DOMAIN_PUBLIC_URL + path);
  return true;
};

export const handleRedirectToOldLink = (path = ""): boolean => {
  if (!BASE_DOMAIN_PUBLIC_URL.includes(window.location.origin)) {
    return false;
  }

  window.location.replace(BASE_OLD_URL + path);
  return true;
};
