import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Spinner from "common/SpinnerFacebook";
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import useAuth from "common/hooks/useAuth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3, 0, 3, 0),
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  children: React.ReactNode;
}

export default function AdminLayout(props: IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const { isLoggedin } = useAuth();

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        setOpen={setOpen}
        isOpen={open}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setOpen={setOpen}
        isOpen={open}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <React.Suspense fallback={<Spinner />}>{props.children}</React.Suspense>
      </main>
    </div>
  );
}
