const items = [
  {
    href: "/publications",
    icon: null,
    title: "Newsletters",
    target: "_self",
  },
  {
    href: "/rankings",
    icon: null,
    title: "Rankings",
    target: "_self",
  },
  {
    href: "/market-participants",
    icon: null,
    title: "Market Participants",
    target: "_self",
  },
  {
    href: "/special-features",
    icon: null,
    title: "Special Features",
    target: "_self",
  },
  {
    href: "/events",
    icon: null,
    title: "Events",
    target: "_self",
  },
];

export default items;
