import admin from "./admin";
import authenticated from "./authenticated";
import userRoutes from "./user";
import { setGlobalLocaleDateFormat } from "./util/utils";

// const targetBuild = process.env.REACT_APP_TARGET_BUILD;
setGlobalLocaleDateFormat();

let routes: any = [...userRoutes, ...authenticated, ...admin];

// switch (targetBuild) {
//   case "admin":
//     // Build admin-only
//     routes = [...admin];
//     break;
//   case "public":
//     // Build public-only
//     routes = [...userRoutes, ...authenticated];
//     break;
//   default:
//   //
// }

export default routes;
