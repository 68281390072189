import { memo } from "react";
import clsx from "clsx";
// import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
// import grey from "@material-ui/core/colors/grey";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Link from "found/Link";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      fullWidth: {
        marginLeft: "calc(50% - 50vw)",
        marginRight: "calc(50% - 50vw)",
      },
      footer: {},
      footerTop: {
        backgroundColor: theme.palette.primary.main,
        padding: "1.1rem 0",
        color: "#fff",
      },
      footerTopLeft: {
        textAlign: "left",
        padding: "45px!important",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      },
      footerTopRight: {
        textAlign: "right",
        padding: "45px!important",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          marginLeft: "120px",
          marginTop: "-60px",
        },
      },
      footerBottom: {
        paddingBottom: "3.5rem",
        paddingTop: "3.5rem",
        background: "#2d3138",
        color: "#dde2e5",
        overflow: "hidden",
      },
      footerLink: {
        color: "#fff",
      },
      footerLinkBottom: {
        color: "#dde2e5",
      },
      footerBottomText: {
        fontSize: "12px",
      },
      copyright: {
        paddingRight: "0.5em",
      },
      copyRight: {
        position: "relative",
        color: "#fff",
        textDecoration: "none",
        "&:hover": {
          textDocoration: "none",
        },
      },
      footerLogo: {
        height: "120px",
        position: "absolute",
        top: "-50px",
        left: "-150px",
      },
      separator: {
        paddingRight: "0.5em",
        paddingLeft: "0.5em",
      },
      link: {
        color: "rgba(0, 0, 0, 0.6)",
        textDecoration: "none",
        "&:hover": {
          textDocoration: "underline",
        },
      },
    }),
  { name: "LayoutFooter" }
);

const Footer = (props) => {
  const classes = useStyles();
  //   const year = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <Box className={clsx(classes.fullWidth, classes.footerTop)}>
        <Container>
          <Grid container spacing={10}>
            <Grid item sm={12} md={8} className={classes.footerTopLeft}>
              <a
                className={classes.footerLink}
                target="_blank"
                href="https://withintelligence.com/privacy/"
                rel="noreferrer"
              >
                Privacy Statement
              </a>
              <span>, </span>
              <Link className={classes.footerLink} to="/terms-conditions">
                Terms & Conditions
              </Link>
              <span> and </span>
              <a
                className={classes.footerLink}
                target="_blank"
                href="https://withintelligence.com/cookies/"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
            </Grid>
            <Grid item sm={12} md={4} className={classes.footerTopRight}>
              <a
                className={`${classes.copyRight}`}
                target="_blank"
                href="https://withintelligence.com/"
                rel="noreferrer"
              >
                <img
                  src="/static/images/with-intelligence.png"
                  alt=""
                  className={classes.footerLogo}
                />
                <span>© 2021-2022 With Intelligence Ltd</span>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.isLoggedin === nextProps.isLoggedin;
};
export default memo(Footer, areEqual);
