import React, { forwardRef, ReactNode } from "react";
//import { Helmet } from 'react-helmet';

type IProps = {
  title: string | "";
  children: ReactNode;
  className?: string;
};

const Page = forwardRef<any, IProps>(({ title, children, ...rest }, ref) => (
  <div ref={ref} {...rest}>
    {children}
  </div>
));

export default Page;
