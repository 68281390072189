/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PublicationAdminEditQueueQueryVariables = {
    id: string;
};
export type PublicationAdminEditQueueQueryResponse = {
    readonly emailqueue: {
        readonly " $fragmentRefs": FragmentRefs<"EditQueueAdminPublication_data">;
    };
};
export type PublicationAdminEditQueueQuery = {
    readonly response: PublicationAdminEditQueueQueryResponse;
    readonly variables: PublicationAdminEditQueueQueryVariables;
};



/*
query PublicationAdminEditQueueQuery(
  $id: ID!
) {
  emailqueue(id: $id) {
    ...EditQueueAdminPublication_data
    id
  }
}

fragment EditQueueAdminPublication_data on Emailqueue {
  id
  type
  publication {
    id
    title
    fileName
    publishedAt
  }
  subject
  publishedDate
  comments
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublicationAdminEditQueueQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Emailqueue",
        "kind": "LinkedField",
        "name": "emailqueue",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditQueueAdminPublication_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublicationAdminEditQueueQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Emailqueue",
        "kind": "LinkedField",
        "name": "emailqueue",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Publication",
            "kind": "LinkedField",
            "name": "publication",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publishedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf356818e79d5ab922101ffa123f0a39",
    "id": null,
    "metadata": {},
    "name": "PublicationAdminEditQueueQuery",
    "operationKind": "query",
    "text": "query PublicationAdminEditQueueQuery(\n  $id: ID!\n) {\n  emailqueue(id: $id) {\n    ...EditQueueAdminPublication_data\n    id\n  }\n}\n\nfragment EditQueueAdminPublication_data on Emailqueue {\n  id\n  type\n  publication {\n    id\n    title\n    fileName\n    publishedAt\n  }\n  subject\n  publishedDate\n  comments\n  status\n}\n"
  }
};
})();
(node as any).hash = 'b2f6e6a361171442c417c8b367a27bac';
export default node;
