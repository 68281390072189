import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Body4 from "common/Typography/Body4";

interface IProps {
  heading?: string;
  summary?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentHeader: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "180px",
      },

      padding: theme.spacing(3),
      backgroundColor: "#26262C",
    },
    titleHeader: {
      marginBottom: theme.spacing(1),
      color: "#FFFFFF",
    },
    headerContainer: {
      width: "100%",
      position: "fixed",
      zIndex: theme.zIndex.drawer + 1,
      top: 65,
      left: 0,
      padding: 0,

      [theme.breakpoints.down("xs")]: {
        top: 57,
      },

      // marginLeft: theme.spacing(-3),
      // marginRight: theme.spacing(-3),
      // marginBottom: theme.spacing(2),
      // [theme.breakpoints.up("md")]: {
      //   paddingLeft: theme.spacing(0),
      //   paddingRight: theme.spacing(0),
      //   marginTop: theme.spacing(-3),
      // },
    },
  })
);

const Banner = ({ heading, summary, children }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Container className={classes.headerContainer} maxWidth={false}>
      <Grid container item direction="column">
        <Grid item xs={12} className={classes.contentHeader}>
          <Typography
            variant="h6"
            component="h6"
            className={classes.titleHeader}
          >
            {heading}
          </Typography>

          <Body4>{summary}</Body4>
        </Grid>
      </Grid>

      {children}
    </Container>
  );
};

export default Banner;
