import { useState, useEffect } from "react";
import { debounce } from "ts-debounce";

export const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState(() => ({
    width: window.innerWidth,
    height: window.innerHeight,
  }));

  useEffect(() => {
    //const onResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    const onResize = () =>
      debounce(
        () =>
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          }),
        100
      );

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};
