/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserAdminResetpasswordQueryVariables = {
    id: string;
};
export type UserAdminResetpasswordQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"ResetPasswordAdminUser_data">;
    };
};
export type UserAdminResetpasswordQuery = {
    readonly response: UserAdminResetpasswordQueryResponse;
    readonly variables: UserAdminResetpasswordQueryVariables;
};



/*
query UserAdminResetpasswordQuery(
  $id: ID!
) {
  user(id: $id) {
    ...ResetPasswordAdminUser_data
    id
  }
}

fragment ResetPasswordAdminUser_data on User {
  id
  email
  firstName
  lastName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAdminResetpasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ResetPasswordAdminUser_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserAdminResetpasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee5cad3fee931dc4ec83c21304f03b78",
    "id": null,
    "metadata": {},
    "name": "UserAdminResetpasswordQuery",
    "operationKind": "query",
    "text": "query UserAdminResetpasswordQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    ...ResetPasswordAdminUser_data\n    id\n  }\n}\n\nfragment ResetPasswordAdminUser_data on User {\n  id\n  email\n  firstName\n  lastName\n}\n"
  }
};
})();
(node as any).hash = '3d6a06239fcd0e59abcc5eee8bde1963';
export default node;
