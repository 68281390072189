/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardLoadingAdminQueryVariables = {};
export type DashboardLoadingAdminQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Loading_data">;
};
export type DashboardLoadingAdminQuery = {
    readonly response: DashboardLoadingAdminQueryResponse;
    readonly variables: DashboardLoadingAdminQueryVariables;
};



/*
query DashboardLoadingAdminQuery {
  ...Loading_data
}

fragment Loading_data on Query {
  me {
    id
    email
    firstName
    lastName
    role
    companyId
    salesforceId
    company {
      id
      salesforceId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salesforceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardLoadingAdminQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Loading_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardLoadingAdminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyId",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4b63171cde05bf84347070ee0dd6599",
    "id": null,
    "metadata": {},
    "name": "DashboardLoadingAdminQuery",
    "operationKind": "query",
    "text": "query DashboardLoadingAdminQuery {\n  ...Loading_data\n}\n\nfragment Loading_data on Query {\n  me {\n    id\n    email\n    firstName\n    lastName\n    role\n    companyId\n    salesforceId\n    company {\n      id\n      salesforceId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cf0e646b3a9e3439dbac93f119307d08';
export default node;
