import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useIsMobile = () => {
  const matches = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down("xs"),
    {
      noSsr: true,
    }
  );
  return matches;
};

export default useIsMobile;
