/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuditAdminViewQueryVariables = {
    id: string;
};
export type AuditAdminViewQueryResponse = {
    readonly audit: {
        readonly " $fragmentRefs": FragmentRefs<"ViewAdminAudit_data">;
    };
};
export type AuditAdminViewQuery = {
    readonly response: AuditAdminViewQueryResponse;
    readonly variables: AuditAdminViewQueryVariables;
};



/*
query AuditAdminViewQuery(
  $id: ID!
) {
  audit(id: $id) {
    ...ViewAdminAudit_data
    id
  }
}

fragment ViewAdminAudit_data on Audit {
  id
  entityId
  entity
  action
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditAdminViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ViewAdminAudit_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditAdminViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entityId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18871b333fcc5e565572ed030ad1cd2a",
    "id": null,
    "metadata": {},
    "name": "AuditAdminViewQuery",
    "operationKind": "query",
    "text": "query AuditAdminViewQuery(\n  $id: ID!\n) {\n  audit(id: $id) {\n    ...ViewAdminAudit_data\n    id\n  }\n}\n\nfragment ViewAdminAudit_data on Audit {\n  id\n  entityId\n  entity\n  action\n}\n"
  }
};
})();
(node as any).hash = '844b827129c8d8549bd4197d9ad275cd';
export default node;
