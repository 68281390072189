export const NEWSLETTER_TYPE_VALUE = 1;
export const RANKING_TYPE_VALUE = 2;
export const MARKET_TYPE_VALUE = 3;
export const SPECIAL_TYPE_VALUE = 4;
export const EVENT_TYPE_VALUE = 5;

export const types = [
  {
    value: NEWSLETTER_TYPE_VALUE,
    label: "HFA Newsletter",
  },
  {
    value: RANKING_TYPE_VALUE,
    label: "Rankings",
  },
  {
    value: MARKET_TYPE_VALUE,
    label: "Market Participants",
  },
  {
    value: SPECIAL_TYPE_VALUE,
    label: "Special Features",
  },
  {
    value: EVENT_TYPE_VALUE,
    label: "Events",
  },
];

export const getPublicationTypeLabel = (type: number): string =>
  types.find((t) => t.value === type)?.label || "";
