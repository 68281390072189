import { lazy } from "react";
// import graphql from "babel-plugin-relay/macro";
import RedirectException from "found/RedirectException";
import HttpError from "found/HttpError";

import Layout from "common/Layouts/Authenticated";
import { isLoggedin } from "common/hooks/useAuth";

const routes = [
  {
    path: "/dashboard",
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'authenticated' */ "./Dashboard")),
    render: ({ props, Component, error, resolving }) => {
      // if (resolving && !isLoggedin()) {
      //   throw new RedirectException(`/user/login`);
      // }

      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props ? props : null} error={error ? error : null} />
        </Layout>
      );
    },
  },
];

export default routes;
