import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

export type Variant = "regular" | "bold";

interface IProps {
  children?: React.ReactNode;
  variant?: Variant;
  color?: string;
  className?: any;
  fullWidth?: boolean;
  onClick?: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ variant, color, fullWidth }: Omit<IProps, "children">) => ({
      color,
      fontStyle: "normal",
      fontWeight:
        variant === "regular"
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightBold,
      lineHeight: "21px",
      display: fullWidth ? "flex" : "inline",
      fontSize: "14px",
    }),
  })
);

const Body4 = ({
  children,
  variant = "regular",
  color = "#FFFFFF",
  className,
  fullWidth = false,
  onClick = () => null,
}: IProps): JSX.Element => {
  const classes = useStyles({ variant, color });

  return (
    <span
      data-component="typography-body-4"
      className={clsx(classes.root, className)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Body4;
