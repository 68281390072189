import React from "react";
import RouterLink from "found/Link";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";

// import Tooltip from "@material-ui/core/Tooltip";
import useRouter from "found/useRouter";
import Body4 from "common/Typography/Body4";
import { ReactComponent as ArrowRightIcon } from "common/images/nav-arrow-right.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    item: {
      display: "flex",
      padding: 0,
    },
    button: {
      color: "#FFFFFF",
      justifyContent: "flex-start",
      letterSpacing: 0,
      padding: "12px 24px",
      minHeight: "45px",
      width: "100%",

      textTransform: "none",

      "& .MuiListItemText-root": {
        margin: 0,
      },

      "& > svg": {
        width: "1.0625rem",
        height: "1.0625rem",
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    title: {
      marginRight: "auto",

      "& > span": {
        lineHeight: 0,
      },
    },
    active: {
      color: theme.palette.primary.main,
      borderLeft: "4px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: "unset",
      "& $icon": {
        color: theme.palette.primary.main,
      },
    },
    dropdownText: {
      fontSize: "0.8125rem",
      lineHeight: "1.5",
      fontWeight: 700,
    },
  })
);

interface IProps {
  className?: string;
  href: string;
  icon: any;
  title: string;
  target: string;
  index: number;
  mainmenu?: boolean;
  defaultActive?: boolean;
  onClickNavItem?: () => void;
  variant?: "default" | "navdropdown";
}

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  target,
  index,
  defaultActive = false,
  mainmenu = true,
  variant = "default",
  onClickNavItem,
  ...rest
}: IProps) => {
  const classes = useStyles(rest);
  const { match } = useRouter();
  const pathname = match?.location?.pathname || "";

  const handleClick = (e: any) => {
    if (index === 0 && mainmenu && variant === "navdropdown") {
      onClickNavItem && onClickNavItem();
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    try {
      const fullUrl = new URL(href);

      if (fullUrl) {
        e.preventDefault();
      }
    } catch {
      return;
    }

    if (target === "_blank") {
      window.open(href, "_blank");
      return;
    }

    window.location.href = href;
  };

  return (
    <Paper
      square
      className={classes.root}
      style={{ background: variant === "navdropdown" ? "#0C0B0D" : "" }}
    >
      <ListItem
        key={title}
        className={clsx(classes.item, className)}
        disableGutters
      >
        <Button
          activeClassName={classes.active}
          className={clsx(
            classes.button,
            pathname === "/articles/search" && index === 0 && classes.active,
            defaultActive && classes.active
          )}
          style={{
            color: variant === "navdropdown" ? "#FFF" : "#63626A",
            boxShadow:
              variant === "navdropdown" ? "none" : "inset 0px -1px 0px #EAEAEB",
            padding: variant === "navdropdown" ? "0.5rem 1rem" : "12px 16px",
          }}
          onClick={(e) => handleClick(e)}
          component={RouterLink}
          to={href}
        >
          {/* {Icon && (
            <ListItemIcon>
              <Tooltip title={title}>
                <Icon className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
          )} */}

          <ListItemText
            className={classes.title}
            primary={
              <Body4
                variant="bold"
                color="inherit"
                className={classes.dropdownText}
              >
                {title}
              </Body4>
            }
          />
          {index === 0 && mainmenu && <ArrowRightIcon />}
          {!mainmenu &&
            React.createElement(Icon, {
              style: { width: "1.0625rem", height: "1.0625rem" },
            })}
        </Button>
      </ListItem>
    </Paper>
  );
};

export default NavItem;
