import { withStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: "4px",
    padding: "8px 16px",
    height: "40px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "14px",
    fontStyle: "normal",

    "& .MuiButton-startIcon": {
      marginRight: "10px",
    },
  },
  // sizeSmall: {
  //   padding: `${theme.spacing(1)}px ${theme.spacing(1) * 3}px`,
  //   fontSize: theme.typography.pxToRem(13),
  // },
  // sizeLarge: {
  //   padding: `${theme.spacing(1) * 3 - 3}px ${theme.spacing(1) * 6}px`,
  //   fontSize: theme.typography.pxToRem(16),
  // },
});

const Button = (props) => {
  return <MuiButton {...props} />;
};

export default withStyles(styles)(Button);
