// import RedirectException from "found/RedirectException";
import { user, isLoggedin } from "common/hooks/useAuth";

export const redirectUrl = process.env.REACT_APP_BASE_DOMAIN_PUBLIC || "/";
export const loginUrl = `${redirectUrl}/user/login`;

export const isAdmin = (): Boolean => {
  if (!isLoggedin()) return false;

  const u = user();
  if (!u) return false;
  if (u && !u.role) return false;

  if (u && u.role && u.role === "admin") return true;

  return false;
};

const RedirectNonAdmin = () => {
  if (!isLoggedin()) {
    // throw new RedirectException(`/user/login`);
    window.location.href = redirectUrl!;
  }

  if (!isAdmin()) {
    window.location.href = redirectUrl!;
  }
};

export default RedirectNonAdmin;
