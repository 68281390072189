/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PublicationAdminViewQueryVariables = {
    id: string;
};
export type PublicationAdminViewQueryResponse = {
    readonly publication: {
        readonly " $fragmentRefs": FragmentRefs<"ViewAdminPublication_data">;
    };
};
export type PublicationAdminViewQuery = {
    readonly response: PublicationAdminViewQueryResponse;
    readonly variables: PublicationAdminViewQueryVariables;
};



/*
query PublicationAdminViewQuery(
  $id: ID!
) {
  publication(id: $id) {
    ...ViewAdminPublication_data
    id
  }
}

fragment ViewAdminPublication_data on Publication {
  id
  title
  fileName
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublicationAdminViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Publication",
        "kind": "LinkedField",
        "name": "publication",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ViewAdminPublication_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublicationAdminViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Publication",
        "kind": "LinkedField",
        "name": "publication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e1e26d5531314a5ae9d747294ec1604",
    "id": null,
    "metadata": {},
    "name": "PublicationAdminViewQuery",
    "operationKind": "query",
    "text": "query PublicationAdminViewQuery(\n  $id: ID!\n) {\n  publication(id: $id) {\n    ...ViewAdminPublication_data\n    id\n  }\n}\n\nfragment ViewAdminPublication_data on Publication {\n  id\n  title\n  fileName\n  createdAt\n}\n"
  }
};
})();
(node as any).hash = '7d59d304ca89979181445a43b88573c6';
export default node;
