/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RankingDownloadAuthenticatedQueryVariables = {
    id: string;
};
export type RankingDownloadAuthenticatedQueryResponse = {
    readonly publication: {
        readonly " $fragmentRefs": FragmentRefs<"DownloadAuthenticatedPublication_data">;
    };
};
export type RankingDownloadAuthenticatedQuery = {
    readonly response: RankingDownloadAuthenticatedQueryResponse;
    readonly variables: RankingDownloadAuthenticatedQueryVariables;
};



/*
query RankingDownloadAuthenticatedQuery(
  $id: ID!
) {
  publication(id: $id) {
    ...DownloadAuthenticatedPublication_data
    id
  }
}

fragment DownloadAuthenticatedPublication_data on Publication {
  id
  title
  topic
  fileName
  filePath
  publishedAt
  visible
  type
  thumbnail
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingDownloadAuthenticatedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Publication",
        "kind": "LinkedField",
        "name": "publication",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DownloadAuthenticatedPublication_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RankingDownloadAuthenticatedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Publication",
        "kind": "LinkedField",
        "name": "publication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "topic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filePath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visible",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e86315e8446ccd4f2666dc1ea5e3c3f",
    "id": null,
    "metadata": {},
    "name": "RankingDownloadAuthenticatedQuery",
    "operationKind": "query",
    "text": "query RankingDownloadAuthenticatedQuery(\n  $id: ID!\n) {\n  publication(id: $id) {\n    ...DownloadAuthenticatedPublication_data\n    id\n  }\n}\n\nfragment DownloadAuthenticatedPublication_data on Publication {\n  id\n  title\n  topic\n  fileName\n  filePath\n  publishedAt\n  visible\n  type\n  thumbnail\n}\n"
  }
};
})();
(node as any).hash = '622a7a3f8e162db403e4952ddfa772cc';
export default node;
