import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  const baseUrl = process.env.REACT_APP_BASE_DOMAIN_PUBLIC;

  return (
    // <Helmet>
    //   <meta property="og:title" content="Hedge Fund Alert" />
    //   <meta
    //     property="og:description"
    //     content="Leverage fresh intel on discreet developments to anticipate industry risks and opportunities with Hedge Fund Alert. This weekly publication conveys the earliest look at key dealings and intelligence in the hedge fund arena."
    //   />

    //   <meta
    //     property="og:image"
    //     content={`${baseUrl}/static/images/1200x1200_FB.jpg`}
    //   />

    //   <meta
    //     name="twitter:image"
    //     content={`${baseUrl}/static/images/1200x630_Twitter.jpg`}
    //   />
    // </Helmet>
    <></>
  );
};

export default Head;
